import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const LoadingSpinner = ({size = 24, className = ""}) => {
    return (
        <div className={className}>
            <div style={{
                animation: `spin 1s linear infinite`,
                border: `${size * 0.125}px solid var(--light-trans)`,
                borderRadius: `50%`,
                borderTop: `${size * 0.125}px solid var(--accent)`,
                height: `${size * 0.75}px`,
                marginBottom: `${size * 0.125}px`,
                marginTop: `${size * 0.125}px`,
                WebkitAnimation: `spin 1s linear infinite`,
                width: `${size * 0.75}px`,
            }}></div>
        </div>
    );
};

LoadingSpinner.propTypes = {
    size: PropTypes.number,
};

export { LoadingSpinner };
