import React from "react";
import PropTypes from "prop-types";

const SegmentedControl = ({options, selectedOption, setSelectedOption, size = 16, className = ""}) => {
    const optionKeys = Object.keys(options);
    const selectedIndex = optionKeys.indexOf(selectedOption);

    const styles = {
        wrapper: {
            maxWidth: "768px",
            width: "100%",
        },
        segmentedControl: {
            position: "relative",
            display: "flex",
            border: "2px solid transparent",
            borderRadius: `${size / 2 + 4}px`,
            overflow: "hidden",
            backgroundColor: "var(--light-trans)",
            fontSize: `${size}px`,
        },
        segment: {
            flex: 1,
            padding: `${size / 4}px`,
            color: "var(--dark)",
            cursor: "pointer",
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            zIndex: 1,
        },
        selectedSegment: {
            position: "absolute",
            top: 0,
            left: 0,
            width: `${100 / optionKeys.length}%`,
            height: "100%",
            backgroundColor: "var(--white)",
            boxShadow: "var(--light-xtrans) 0px 0px 2px 0px, var(--light-trans) 0px 12px 24px -4px",
            transform: `translateX(${selectedIndex * 100}%)`,
            transition: "transform 0.25s ease",
            zIndex: 0,
            borderRadius: `${size / 2}px`
        },
        selectedText: {
            color: "#000",
        },
    };

    return (
        <div className={className} style={styles.wrapper}>
            <div style={styles.segmentedControl}>
                <div style={styles.selectedSegment}></div>
                {optionKeys.map((key) => (
                    <button
                        key={key}
                        style={{
                            ...styles.segment,
                            ...(selectedOption === key ? styles.selectedText : {}),
                        }}
                        onClick={() => setSelectedOption(key)}
                    >
                        {options[key]}
                    </button>
                ))}
            </div>
        </div>
    );
};

SegmentedControl.propTypes = {
    options: PropTypes.object.isRequired,
    selectedOption: PropTypes.string.isRequired,
    setSelectedOption: PropTypes.func.isRequired,
};

export { SegmentedControl };
