import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, FullPageLoading, LoadingSpinner, PageTitle, SearchableSelect } from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse, requestTypes } from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { routes, routeWithParams } from "helpers/routes";
import { useHotkeys } from "react-hotkeys-hook";

const GrantSiteFilestoreAccess = () => {
    const {t} = useTranslation();
    const {siteId} = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const [userId, setUserId] = useState(null);

    const [isGranting, setIsGranting] = useState(false);

    useHotkeys("esc", () => navigateBack());

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.siteFilestoreAccessOptions;
        createEmptyApiResponse(requestTypes.GET, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl(siteId)).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const processUserOptions = (options) => {
        let processedOptions = {};
        options.forEach(e => {
            processedOptions[e.id] = e.name;
        })
        return processedOptions;
    }

    const grant = () => {
        apiRequest(
            (data) => {
            },
            setError,
            requestTypes.POST,
            apiEndpoints.siteFilestoreAccess.constructUrl(siteId, userId)
        ).then(apiRequestSuccess => {
            if (apiRequestSuccess) navigateBack();
            else alert(t("errorGrantingPermissions"));
        });
    }

    const navigateBack = () => {
        navigate(routeWithParams(routes.site.path, {id: siteId}));
    }

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle>
                {loaded ? (
                    <>
                        {t(`grantSiteFilestorePermissions`, {site: data.site.name})}
                    </>
                ) : (
                    <LoadingSpinner size={46}/>
                )}
            </PageTitle>
            <div className="d-flex flex-wrap align-items-stretch">
                <div className="d-flex flex-column col-12 col-lg-6">
                    <Card className="flex-grow-1">
                        <div
                            className="align-items-center d-flex flex-column h-100 justify-content-center">
                            <div className="fw-bold text-size-xl my-3">{t("site")}</div>
                            {loaded ? (
                                <div className="mb-3 col-6">
                                    <SearchableSelect loaded={loaded}
                                                      options={{[siteId]: data.site.name}}
                                                      placeholder={t("selectSite")}
                                                      setSelectedOption={() => {
                                                      }}
                                                      selectedOption={siteId}/>
                                </div>
                            ) : (
                                <LoadingSpinner size={24}/>
                            )}
                        </div>
                    </Card>
                </div>
                <div className="d-flex flex-column col-12 col-lg-6">
                    <Card className="flex-grow-1">
                        <div
                            className="align-items-center d-flex flex-column h-100 justify-content-center">
                            <div className="fw-bold text-size-xl my-3">{t("staff")}</div>
                            {loaded ? (
                                <div className="mb-3 col-6">
                                    <SearchableSelect loaded={loaded}
                                                      options={processUserOptions(data.staff)}
                                                      placeholder={t("selectStaff")}
                                                      setSelectedOption={userId => setUserId(userId)}
                                                      selectedOption={userId}/>
                                </div>
                            ) : (
                                <LoadingSpinner size={24}/>
                            )}
                        </div>
                    </Card>
                </div>
                <div className="col-12 my-2 my-lg-3 d-flex justify-content-center">
                    {isGranting ? (
                        <LoadingSpinner size={38}/>
                    ) : (
                        <>
                            <div className="col-6 text-end">
                                <button type="button" className="btn bg-accent me-2 me-lg-3 text-white"
                                        onClick={() => grant()}
                                        disabled={!userId}>
                                    {t("grant")}
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn bg-med ms-2 ms-lg-3 text-white"
                                        onClick={() => navigateBack()}>
                                    {t("cancel")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export { GrantSiteFilestoreAccess };
