import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { invisibleInputStyles, LoadingSpinner } from "components/utils/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { convertToPrettyDateWithFullYear } from "helpers/date";
import { useTranslation } from "react-i18next";

const EditableTitleValueLine = ({
                                    loaded,
                                    title,
                                    value,
                                    type = "text",
                                    inputMode = null,
                                    inputAttributes = {},
                                    className = "",
                                    clickToEdit = "true",
                                    onSave
                                }) => {
    const {t} = useTranslation();

    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [originalValue, setOriginalValue] = useState(value);
    const [currentValue, setCurrentValue] = useState(value);
    const inputRef = useRef(null);

    useEffect(() => {
        setOriginalValue(value);
        if (!isEditing) setCurrentValue(value);
        if (isSaving) setIsSaving(false);
    }, [value]);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    const handleEditClick = () => {
        setIsEditing(true);
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 0);
    };

    const handleSaveClick = async () => {
        if (currentValue !== originalValue) {
            setIsSaving(true);
            const saveSuccess = await onSave(currentValue);
            if (!saveSuccess) setIsSaving(false);
            else setIsEditing(false);
        } else {
            setIsEditing(false);
        }
    };

    const handleCancelClick = () => {
        setCurrentValue(originalValue);
        setIsEditing(false);
    };

    const handleChange = (event) => {
        if (type === "checkbox") {
            setCurrentValue(event.target.checked);
        } else if (type === "number") {
            const inputValue = event.target.value;
            if (inputValue === "") {
                setCurrentValue(""); // Allow clearing the input
            } else {
                setCurrentValue(Number(inputValue));
            }
        } else {
            setCurrentValue(event.target.value);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSaveClick();
        }
        if (event.key === "Escape") {
            handleCancelClick();
        }
    };

    const handleBlur = (event) => {
        if (event.target.type === "text" && type === "number") {
            event.target.type = "number"; // Revert type back to number on blur
        }
    };

    return (
        <div className={`d-flex w-100 ${className}`}>
            <div className={`fw-bold me-1 text-nowrap`}>{title}</div>
            {loaded && !isSaving ? (
                isEditing ? (
                    <>
                        <div className={`align-items-center d-flex flex-grow-1 pe-2 text-color-med`}>
                            {type === "checkbox" ? (
                                <input
                                    type="checkbox"
                                    checked={!!currentValue}
                                    onChange={handleChange}
                                    {...inputAttributes}
                                />
                            ) : (
                                <input
                                    type={type}
                                    inputMode={inputMode}
                                    value={currentValue}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onBlur={handleBlur}
                                    style={{
                                        ...invisibleInputStyles,
                                        width: type === `date` ? `` : `100%`,
                                    }}
                                    ref={inputRef}
                                    {...inputAttributes}
                                />
                            )}
                        </div>
                        <div className="text-color-accent text-end">
                            <FontAwesomeIcon
                                icon={faCheck}
                                onClick={handleSaveClick}
                                className="clickable pe-2"
                            />
                            <FontAwesomeIcon
                                icon={faTimes}
                                onClick={handleCancelClick}
                                className="clickable"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className="align-items-center d-flex flex-grow-1 text-color-med"
                            style={{cursor: clickToEdit ? "pointer" : "auto"}}
                            onClick={() => {
                                clickToEdit ? handleEditClick() : null;
                            }}
                        >
                            <div>
                                {
                                    type === "date" ? convertToPrettyDateWithFullYear(currentValue) :
                                        type === "checkbox" ? (!!currentValue ? t("yes") : t("no")) :
                                            currentValue
                                }
                            </div>
                        </div>
                        <div className="text-color-accent text-end">
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                onClick={handleEditClick}
                                className="clickable"
                            />
                        </div>
                    </>
                )
            ) : (
                <LoadingSpinner/>
            )}
        </div>
    );
};

EditableTitleValueLine.propTypes = {
    loaded: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.node,
    ]),
    type: PropTypes.string,
    inputAttributes: PropTypes.object,
    className: PropTypes.string,
    onSave: PropTypes.func.isRequired,
};

export { EditableTitleValueLine };
