import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { routeWithParams } from "helpers/routes";

const LinkWithParams = ({baseUrl, params = null, text, children, noTextDecoration = false, state = null, ...rest}) => {
    return (
        <NavLink
            className={`text-color-accent ${noTextDecoration ? `text-decoration-none` : ``}`}
            to={params ? routeWithParams(baseUrl, params) : baseUrl}
            state={state}
            {...rest}
        >
            {text && text}
            {children && children}
        </NavLink>
    );
};

LinkWithParams.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    params: PropTypes.object,
    text: PropTypes.string,
    children: PropTypes.node,
    state: PropTypes.object,
};

export { LinkWithParams };
