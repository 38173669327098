import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    EditableTitleValueLine,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    PageTitle,
    SearchableList,
    tooltipStyles
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse, requestTypes } from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { routes, routeWithParams } from "helpers/routes";
import { convertToPrettyDateWithYear } from "helpers/date";
import { uniqueId } from "helpers/random";
import { useHotkeys } from "react-hotkeys-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkSlash, faPlus, faTrashAlt, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { HistoryBackButton } from "components/utils/navigation";
import { useHistoryContext } from "contexts/HistoryProvider";
import useConfirm from "hooks/useConfirmModal";

const Site = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const {updateCurrentRouteName} = useHistoryContext();

    const {confirm, ConfirmModal} = useConfirm();

    const [loaded, setLoaded] = useState(false);
    const [dealsLoaded, setDealsLoaded] = useState(false);
    const [deploymentsLoaded, setDeploymentsLoaded] = useState(false);
    const [filestoreAccessLoaded, setFilestoreAccessLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const [showNonRemovablePermissions, setShowNonRemovablePositions] = useState(false);

    useHotkeys("esc", () => navigate(routes.sites.path));

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.site;
        createEmptyApiResponse(requestTypes.GET, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl(id)).then(apiRequestSuccess => {
            if (apiRequestSuccess) {
                setLoaded(true);
                setDealsLoaded(true);
                setDeploymentsLoaded(true);
                setFilestoreAccessLoaded(true);
            }
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (!!data && !!data.summary && !!data.summary.name) updateCurrentRouteName(data.summary.name);
    }, [data]);

    const deleteSite = async () => {
        const confirmation = await confirm(t("confirmation"), t("confirmSiteDeletion"), t("yes"), t("no"));
        if (!confirmation) return;
        return updateSite("deleted", true);
    }

    const restoreSite = () => {
        return updateSite("deleted", false);
    }

    const updateSite = async (field, value) => {
        return await apiRequest(setData, setError, requestTypes.PUT, apiEndpoints.site.constructUrl(id), {[field]: value});
    };

    const unlinkDeal = async (dealId) => {
        const confirmation = await confirm(t("confirmation"), t("confirmDealUnlinking"), t("yes"), t("no"));
        if (!confirmation) return;
        setDealsLoaded(false);
        apiRequest(
            () => {
            },
            setError,
            requestTypes.DELETE,
            apiEndpoints.linkDealAndSite.constructUrl(dealId, id)
        ).then(deleteSuccess => {
            apiRequest(setData, setError, requestTypes.GET, apiEndpoints.site.constructUrl(id)).then(apiRequestSuccess => {
                if (deleteSuccess && apiRequestSuccess) setDealsLoaded(true);
            })
        });
    }

    const dealsTableHeaders = {
        name: t("name"),
        unlink: null
    }

    const processDeals = (deals) => {
        return deals.map(deal => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.deal.path} params={{id: deal.id}}
                                    text={deal.name} key={uniqueId()}/>),
                unlink: (
                    <div className="align-items-center d-flex flex-nowrap justify-content-end h-100">
                        <FontAwesomeIcon icon={faLinkSlash} className="clickable text-color-accent"
                                         onClick={() => unlinkDeal(deal.id)}/>
                    </div>
                )
            };
        });
    };

    const unlinkDeployment = async (deploymentId) => {
        const confirmation = await confirm(t("confirmation"), t("confirmDeploymentUnlinking"), t("yes"), t("no"));
        if (!confirmation) return;
        setDeploymentsLoaded(false);
        apiRequest(
            () => {
            },
            setError,
            requestTypes.DELETE,
            apiEndpoints.linkDeploymentAndSite.constructUrl(deploymentId, id)
        ).then(deleteSuccess => {
            apiRequest(setData, setError, requestTypes.GET, apiEndpoints.site.constructUrl(id)).then(apiRequestSuccess => {
                if (deleteSuccess && apiRequestSuccess) setDeploymentsLoaded(true);
            })
        });
    }

    const deploymentsTableHeaders = {
        name: t("name"),
        travelDates: t("travelDates"),
        unlink: null
    }

    const processDeployments = (deployments) => {
        return deployments.map(deployment => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.deployment.path} params={{id: deployment.id}}
                                    text={deployment.name} key={uniqueId()}/>),
                travelDates: `${convertToPrettyDateWithYear(deployment.travelStartDate)}-${convertToPrettyDateWithYear(deployment.travelEndDate)}`,
                unlink: (
                    <div className="align-items-center d-flex flex-nowrap justify-content-end h-100">
                        <FontAwesomeIcon icon={faLinkSlash} className="clickable text-color-accent"
                                         onClick={() => unlinkDeployment(deployment.id)}/>
                    </div>
                )
            };
        });
    };

    const removeFilestoreAccess = async (userId) => {
        const confirmation = await confirm(t("confirmation"), t("confirmFilestoreAccessRemoval"), t("yes"), t("no"));
        if (!confirmation) return;
        setFilestoreAccessLoaded(false);
        apiRequest(
            () => {
            },
            setError,
            requestTypes.DELETE,
            apiEndpoints.siteFilestoreAccess.constructUrl(id, userId)
        ).then(deleteSuccess => {
            apiRequest(setData, setError, requestTypes.GET, apiEndpoints.site.constructUrl(id)).then(apiRequestSuccess => {
                if (deleteSuccess && apiRequestSuccess) setFilestoreAccessLoaded(true);
            })
        });
    }

    const filestoreAccessTableHeaders = {
        name: t("name"),
        remove: null
    }

    const processFilestoreAccess = (removablePermissions, nonRemovablePermissions) => {
        const removablePermissionRows = removablePermissions.map(permission => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.operator.path} params={{id: permission.id}}
                                    text={permission.name} key={uniqueId()}/>),
                remove: (
                    <div className="align-items-center d-flex flex-nowrap justify-content-end h-100">
                        <FontAwesomeIcon icon={faTrashAlt} className="clickable text-color-accent"
                                         onClick={() => removeFilestoreAccess(permission.id)}/>
                    </div>
                )
            };
        });
        let nonRemovablePermissionRows = [];
        if (showNonRemovablePermissions) {
            nonRemovablePermissionRows = nonRemovablePermissions.map(permission => {
                const tooltipId = uniqueId();
                return {
                    name: (
                        <LinkWithParams baseUrl={routes.operator.path} params={{id: permission.id}}
                                        text={permission.name} key={uniqueId()}/>),
                    remove: (
                        <>
                            <div className="align-items-center d-flex flex-nowrap justify-content-end h-100">
                                <FontAwesomeIcon
                                    data-tooltip-id={`cannot-delete-permission-${tooltipId}-tooltip`}
                                    icon={faTrashAlt}
                                    className="not-clickable text-color-light"
                                />
                            </div>
                            <Tooltip
                                id={`cannot-delete-permission-${tooltipId}-tooltip`}
                                place="left"
                                content={t("cannotDeletePermissionViaAssignment")}
                                style={tooltipStyles}
                            />
                        </>
                    )
                };
            });
        }
        return [...removablePermissionRows, ...nonRemovablePermissionRows];
    }

    const addFilestoreAccess = () => {
        navigate(
            routeWithParams(
                routes.newFilestore.path,
                {siteId: id}
            ),
            {
                state: {
                    siteName: data.summary.name
                }
            }
        )
    }

    const filestoresTableHeaders = {
        name: t("name"),
        createdAt: t("created")
    }

    const processFilestores = (filestores) => {
        return filestores.map(filestore => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.filestore.path} params={{id: filestore.id}}
                                    text={filestore.name} key={uniqueId()}/>),
                createdAt: convertToPrettyDateWithYear(filestore.createdAt),
            };
        });
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <ConfirmModal />
            <PageTitle className="align-items-center d-flex">
                <div>
                    <HistoryBackButton className="mb-1"/>
                    {loaded ? (
                        <div className="align-items-center d-flex">
                            {data.summary.name}
                            {data.summary.deleted && (
                                <div className="badge badge-accent ms-2">{t("DELETED")}</div>
                            )}
                        </div>
                    ) : (
                        <LoadingSpinner size={46}/>
                    )}
                </div>
                <div className="flex-grow-1"></div>
                {loaded && (
                    data.summary.deleted ? (
                        <>
                            <div className="clickable text-end text-decoration-none">
                                <FontAwesomeIcon
                                    data-tooltip-id="restore-site-tooltip"
                                    icon={faUndo}
                                    className="text-color-accent"
                                    onClick={() => restoreSite()}
                                />
                            </div>
                            <Tooltip
                                id={`restore-site-tooltip`}
                                place="left"
                                content={t("restoreSite")}
                                style={tooltipStyles}
                            />
                        </>
                    ) : (
                        <>
                            <div className="clickable text-end text-decoration-none">
                                <FontAwesomeIcon
                                    data-tooltip-id="delete-site-tooltip"
                                    icon={faTrashAlt}
                                    className="text-color-accent"
                                    onClick={() => deleteSite()}
                                />
                            </div>
                            <Tooltip
                                id={`delete-site-tooltip`}
                                place="left"
                                content={t("deleteSite")}
                                style={tooltipStyles}
                            />
                        </>
                    )
                )}
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("summary")}</div>
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("name")}:`}
                            value={data.summary.name}
                            className="mt-2"
                            onSave={async (value) => {
                                return await updateSite(`name`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("citizenshipRequirement")}:`}
                            value={data.summary.citizenshipRequirement}
                            onSave={async (value) => {
                                return await updateSite(`citizenshipRequirement`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("notes")}:`}
                            value={data.summary.notes}
                            onSave={async (value) => {
                                return await updateSite(`notes`, value);
                            }}
                        />
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("location")}</div>
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("address")}:`}
                            value={data.location.address}
                            className="mt-2"
                            onSave={async (value) => {
                                return await updateSite(`address`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("address2")}:`}
                            value={data.location.address2}
                            onSave={async (value) => {
                                return await updateSite(`address2`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("city")}:`}
                            value={data.location.city}
                            onSave={async (value) => {
                                return await updateSite(`city`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("state")}:`}
                            value={data.location.state}
                            onSave={async (value) => {
                                return await updateSite(`state`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("zip")}:`}
                            value={data.location.zip}
                            onSave={async (value) => {
                                return await updateSite(`zip`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("country")}:`}
                            value={data.location.country}
                            onSave={async (value) => {
                                return await updateSite(`country`, value);
                            }}
                        />
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <SearchableList
                            loaded={loaded && dealsLoaded}
                            title={t("linkedDeals")}
                            columnNames={dealsTableHeaders}
                            data={processDeals(data.deals)}
                        />
                        <LinkWithParams
                            baseUrl={routes.linkDealToSite.path}
                            params={{siteId: id}}
                        >
                            <div
                                className="clickable d-flex justify-content-center align-items-center text-color-accent">
                                <div className="pe-1">
                                    <FontAwesomeIcon icon={faPlus}/>
                                </div>
                                <div className="text-decoration-none">{t("linkDeal")}</div>
                            </div>
                        </LinkWithParams>
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded && deploymentsLoaded}
                            title={t("linkedDeployments")}
                            columnNames={deploymentsTableHeaders}
                            data={processDeployments(data.deployments)}
                        />
                        <LinkWithParams
                            baseUrl={routes.linkDeploymentToSite.path}
                            params={{siteId: id}}
                        >
                            <div
                                className="clickable d-flex justify-content-center align-items-center text-color-accent">
                                <div className="pe-1">
                                    <FontAwesomeIcon icon={faPlus}/>
                                </div>
                                <div className="text-decoration-none">{t("linkDeployment")}</div>
                            </div>
                        </LinkWithParams>
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <SearchableList
                            loaded={loaded && filestoreAccessLoaded}
                            title={
                                <>
                                    <div>{t("filestoreAccess")}</div>
                                    <div className="d-flex">
                                        <input
                                            className="me-1"
                                            id="show-non-removable-permissions-checkbox"
                                            type="checkbox"
                                            checked={!!showNonRemovablePermissions}
                                            onChange={event => setShowNonRemovablePositions(event.target.checked)}
                                        />
                                        <label
                                            className="fw-normal no-select text-size-sm text-med"
                                            for="show-non-removable-permissions-checkbox"
                                        >{t("showImplicitPermissions")}</label>

                                    </div>
                                </>
                            }
                            columnNames={filestoreAccessTableHeaders}
                            data={processFilestoreAccess(data.filestoreAccess.direct, data.filestoreAccess.viaAssignment)}
                        />
                        <LinkWithParams
                            baseUrl={routes.grantSiteFilestoreAccess.path}
                            params={{siteId: id}}
                        >
                            <div
                                className="clickable d-flex justify-content-center align-items-center text-color-accent">
                                <div className="pe-1">
                                    <FontAwesomeIcon icon={faPlus}/>
                                </div>
                                <div className="text-decoration-none">{t("grantFilestoreAccess")}</div>
                            </div>
                        </LinkWithParams>
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded}
                            title={t("filestores")}
                            columnNames={filestoresTableHeaders}
                            data={processFilestores(data.filestores)}
                        />
                        <LinkWithParams
                            baseUrl={routes.newFilestore.path}
                            params={{siteId: id}}
                        >
                            <div
                                className="clickable d-flex justify-content-center align-items-center text-color-accent">
                                <div className="pe-1">
                                    <FontAwesomeIcon icon={faPlus}/>
                                </div>
                                <div className="text-decoration-none">{t("newFilestore")}</div>
                            </div>
                        </LinkWithParams>
                    </Card>
                </div>
            </div>
        </>
    )
}

export { Site };