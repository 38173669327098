import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { de, enUS, es, fr, it } from "date-fns/locale";
import { formatDistanceToNow } from "date-fns";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        debug: false,
        ns: [
            "global",
            "api"
        ],
        defaultNS: "global",
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
    });

const languageOptions = {
    "en": "English",
    "es": "Español",
    "fr": "Français",
    "de": "Deutsch",
    "it": "Italiano",
    //"pt": "Português",
};

const localeMapping = {
    "en": enUS,
    "es": es,
    "fr": fr,
    "de": de,
    "it": it,
    //"pt": pt,
};

export { i18n as default, languageOptions };

export const timeAgo = (timestamp, capitalize = true) => {
    const locale = localeMapping[i18n.language] || enUS;
    const timeAgo = formatDistanceToNow(new Date(timestamp), {addSuffix: true, locale});
    if (capitalize) return timeAgo.charAt(0).toUpperCase() + timeAgo.slice(1);
    return timeAgo;
};