import React, { useEffect, useMemo, useState } from "react";
import {
    Card,
    CopyButton,
    DownloadButton,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    PageTitle,
    ProportionSummary,
    SearchableList,
    stateColors,
    TitleValueLine
} from "components/utils/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { apiEndpoints, apiRequest, createEmptyApiResponse, requestTypes } from "services/api";
import { routes } from "helpers/routes";
import { useTranslation } from "react-i18next";
import { convertToPrettyDate } from "helpers/date";
import { uniqueId } from "helpers/random";

const Home = ({userInfo}) => {
    const {t} = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.home;
        createEmptyApiResponse(requestTypes.GET, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl()).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const activeRobots = useMemo(() =>
            !data ? 0 : data.fleetStatus.deployed.quantity + data.fleetStatus.jobReady.quantity,
        [data]
    );

    const twoWeekPeakDemand = useMemo(() =>
            !data ? 0 : Math.max(data.thisWeek.robotsQuantity, data.nextWeek.robotsQuantity, data.twoWeeks.robotsQuantity),
        [data]
    );

    const fleetStatusText = () => {
        //return `${data.fleetStatus.reserved.quantity}${t("times")} ${t("reserved")}: ${data.fleetStatus.reserved.list.join(`, `)}
        return `${data.fleetStatus.deployed.quantity}${t("times")} ${t("deployed")}: ${data.fleetStatus.deployed.list.join(`, `)}
${data.fleetStatus.jobReady.quantity}${t("times")} ${t("jobReady")}: ${data.fleetStatus.jobReady.list.join(`, `)}
${data.fleetStatus.developmentReady.quantity}${t("times")} ${t("developmentReady")}: ${data.fleetStatus.developmentReady.list.join(`, `)}
${data.fleetStatus.inMaintenance.quantity}${t("times")} ${t("inMaintenance")}: ${data.fleetStatus.inMaintenance.list.join(`, `)}`;
    }

    const operatorStatusText = () => {
        return `${data.operatorStatus.deployed.quantity}${t("times")} ${t("deployed")}: ${data.operatorStatus.deployed.list.join(`, `)}
${data.operatorStatus.inOffice.quantity}${t("times")} ${t("inOffice")}: ${data.operatorStatus.inOffice.list.join(`, `)}
${data.operatorStatus.uft.quantity}${t("times")} ${t("uft")}: ${data.operatorStatus.uft.list.join(`, `)}
${data.operatorStatus.pto.quantity}${t("times")} ${t("pto")}: ${data.operatorStatus.pto.list.join(`, `)}`;
    }

    const deploymentsTableHeaders = {
        name: t("name"),
        travelDates: t("travelDates"),
        location: t("location"),
        robots: t("robots"),
        staff: t("staff"),
        foremanAssigned: t("foremanAssigned"),
        confidenceLevel: t("confidence")
    }

    const processDeployments = (deployments) => {
        return deployments.map(deployment => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.deployment.path} params={{id: deployment.id}}
                                    text={deployment.name} key={uniqueId()}/>),
                travelDates: `${convertToPrettyDate(deployment.travelStartDate)}-${convertToPrettyDate(deployment.travelEndDate)}`,
                location: deployment.location,
                robots: (() => {
                    const robots = deployment.robots.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.robot.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return (
                        <div>
                            {deployment.robotsAssigned}/{deployment.robotsRequired}{!!deployment.robots.length && (<>&nbsp;({robots})</>)}
                        </div>
                    );
                })(),
                staff: (() => {
                    const staff = deployment.staff.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.operator.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return (
                        <div>
                            {deployment.staffAssigned}/{deployment.staffRequired}{!!deployment.staff.length && (<>&nbsp;({staff})</>)}
                        </div>
                    );
                })(),
                foremanAssigned: deployment.staffRequired === 0 ? t("na") : (deployment.foremanAssigned ? t("yes") : t("no")),
                confidenceLevel: t(`api:deployment_confidence_level_list.${deployment.confidenceLevel}`)
            };
        });
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle>{t("overview")}</PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-lg-6">
                    {!loaded ? (
                        <Card>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%"
                            }}>
                                <LoadingSpinner size={48}></LoadingSpinner>
                            </div>
                        </Card>
                    ) : (
                        <Card
                            color={activeRobots > twoWeekPeakDemand + 2 ? `var(--bs-success-bg-subtle)` : (activeRobots >= twoWeekPeakDemand ? `var(--bs-warning-bg-subtle)` : `var(--bs-danger-bg-subtle)`)}>
                            <div
                                className="fw-bold py-3"
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}>
                                {activeRobots > twoWeekPeakDemand + 2 ? (
                                    <>
                                        <FontAwesomeIcon icon={faCheckCircle} size="2x"
                                                         style={{marginBottom: "8px"}}/>
                                        {t("fleetHealthGood")}
                                    </>
                                ) : (activeRobots >= twoWeekPeakDemand ? (
                                    <>
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x"
                                                         style={{marginBottom: "8px"}}/>
                                        {t("fleetHealthRisk")}
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faTimesCircle} size="2x"
                                                         style={{marginBottom: "8px"}}/>
                                        {t("fleetHealthPoor")}
                                    </>
                                ))}
                                <br/>
                                {`${t("activeFleet")}: ${activeRobots}.  ${t("peakTwoWeekDemand")}: ${twoWeekPeakDemand}.`}
                            </div>
                        </Card>
                    )}
                    <Card>
                        <div className="fw-bold text-size-lg">{t("fleetStatus")}</div>
                        {/*<ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetStatus.reserved.quantity}
                            quantityOf={t("reserved")}
                            list={data.fleetStatus.reserved.list}
                            proportion={data.fleetStatus.reserved.proportion}
                            color={stateColors.reserved}
                        />*/}
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetStatus.deployed.quantity}
                            quantityOf={t("deployed")}
                            list={data.fleetStatus.deployed.list}
                            proportion={data.fleetStatus.deployed.proportion}
                            color={stateColors.deployed}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetStatus.jobReady.quantity}
                            quantityOf={t("jobReady")}
                            list={data.fleetStatus.jobReady.list}
                            proportion={data.fleetStatus.jobReady.proportion}
                            color={stateColors.jobReady}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetStatus.developmentReady.quantity}
                            quantityOf={t("developmentReady")}
                            list={data.fleetStatus.developmentReady.list}
                            proportion={data.fleetStatus.developmentReady.proportion}
                            color={stateColors.developmentReady}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.fleetStatus.inMaintenance.quantity}
                            quantityOf={t("inMaintenance")}
                            list={data.fleetStatus.inMaintenance.list}
                            proportion={data.fleetStatus.inMaintenance.proportion}
                            color={stateColors.inMaintenance}
                            marginBottom={false}
                        />
                        {!loaded ? (
                            <div className="mt-4">
                                <LoadingSpinner size={32}/>
                            </div>
                        ) : (
                            <div className="d-flex mt-4">
                                <CopyButton text={fleetStatusText()} size={24} className="pe-1"/>
                                <DownloadButton text={fleetStatusText()} size={24}/>
                            </div>
                        )}
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("operatorStatus")}</div>
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.operatorStatus.deployed.quantity}
                            quantityOf={t("deployed")}
                            list={data.operatorStatus.deployed.list}
                            proportion={data.operatorStatus.deployed.proportion}
                            color={stateColors.deployed}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.operatorStatus.inOffice.quantity}
                            quantityOf={t("inOffice")}
                            list={data.operatorStatus.inOffice.list}
                            proportion={data.operatorStatus.inOffice.proportion}
                            color={stateColors.inOffice}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.operatorStatus.uft.quantity}
                            quantityOf={t("uft")}
                            list={data.operatorStatus.uft.list}
                            proportion={data.operatorStatus.uft.proportion}
                            color={stateColors.uft}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.operatorStatus.pto.quantity}
                            quantityOf={t("pto")}
                            list={data.operatorStatus.pto.list}
                            proportion={data.operatorStatus.pto.proportion}
                            color={stateColors.pto}
                            marginBottom={false}
                        />
                        {!loaded ? (
                            <div className="mt-4">
                                <LoadingSpinner size={32}/>
                            </div>
                        ) : (
                            <div className="d-flex mt-4">
                                <CopyButton text={operatorStatusText()} size={24} className="pe-1"/>
                                <DownloadButton text={operatorStatusText()} size={24}/>
                            </div>
                        )}
                    </Card>
                    <Card>
                        {!loaded ? (
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%"
                            }}>
                                <LoadingSpinner size={48}></LoadingSpinner>
                            </div>
                        ) : (
                            <>
                                <div className="fw-bold text-size-xl">{data.supportStaff.numSupportStaff}</div>
                                <div className="text-color-med">{t("supportStaffDeployed")}</div>
                            </>
                        )}
                    </Card>
                </div>
            </div>
            <PageTitle>{t("thisWeek")}</PageTitle>
            <div>
                <Card>
                    <div className="fw-bold text-size-lg">{t("summary")}</div>
                    <TitleValueLine
                        loaded={loaded}
                        title={`${t("deployments")}:`}
                        value={data.thisWeek.deploymentsQuantity}
                        className="mt-2"
                    />
                    <TitleValueLine
                        loaded={loaded}
                        title={`${t("robots")}:`}
                        value={data.thisWeek.robotsQuantity}
                    />
                    <TitleValueLine
                        loaded={loaded}
                        title={`${t("staff")}:`}
                        value={data.thisWeek.staffQuantity}
                    />
                    <SearchableList
                        loaded={loaded}
                        title={t("deployments")}
                        columnNames={deploymentsTableHeaders}
                        data={processDeployments(data.thisWeek.deploymentsList)}
                        className="mt-4"
                    />
                </Card>
                <PageTitle>{t("nextWeek")}</PageTitle>
                <Card>
                    <div className="fw-bold text-size-lg">{t("summary")}</div>
                    <TitleValueLine
                        loaded={loaded}
                        title={`${t("deployments")}:`}
                        value={data.nextWeek.deploymentsQuantity}
                        className="mt-2"
                    />
                    <TitleValueLine
                        loaded={loaded}
                        title={`${t("robots")}:`}
                        value={data.nextWeek.robotsQuantity}
                    />
                    <TitleValueLine
                        loaded={loaded}
                        title={`${t("staff")}:`}
                        value={data.nextWeek.staffQuantity}
                    />
                    <SearchableList
                        loaded={loaded}
                        title={t("deployments")}
                        columnNames={deploymentsTableHeaders}
                        data={processDeployments(data.nextWeek.deploymentsList)}
                        className={"mt-4"}
                    />
                </Card>
                <PageTitle>{t("twoWeeks")}</PageTitle>
                <Card>
                    <div className="fw-bold text-size-lg">{t("summary")}</div>
                    <TitleValueLine
                        loaded={loaded}
                        title={`${t("deployments")}:`}
                        value={data.twoWeeks.deploymentsQuantity}
                        className="mt-2"
                    />
                    <TitleValueLine
                        loaded={loaded}
                        title={`${t("robots")}:`}
                        value={data.twoWeeks.robotsQuantity}
                    />
                    <TitleValueLine
                        loaded={loaded}
                        title={`${t("staff")}:`}
                        value={data.twoWeeks.staffQuantity}
                    />
                    <SearchableList
                        loaded={loaded}
                        title={t("deployments")}
                        columnNames={deploymentsTableHeaders}
                        data={processDeployments(data.twoWeeks.deploymentsList)}
                        className={"mt-4"}
                    />
                </Card>
            </div>
        </>
    );
};

export { Home };