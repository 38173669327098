import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "helpers/translations";

const root = ReactDOM.createRoot(document.getElementById(`root`));
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
