export const listToSentence = (list, conjunction = "and") => {
    if (list.length === 0) {
        return "";
    }
    if (list.length === 1) {
        return list[0];
    }
    if (list.length === 2) {
        return `${list[0]} ${conjunction} ${list[1]}`;
    }
    return `${list.slice(0, -1).join(", ")} ${conjunction} ${list[list.length - 1]}`;
};

export const camelCaseToWords = (camelCaseString) => {
    const result = camelCaseString.replace(/([A-Z])/g, " $1");
    return result.replace(/\b\w/g, (char) => char.toUpperCase());
};