import { parsePhoneNumberFromString } from "libphonenumber-js";

export function formatPhoneNumber(phone) {
    let formattedPhone = false;
    if (!!phone) {
        const parsedPhone = parsePhoneNumberFromString(phone, "US");
        if (parsedPhone && parsedPhone.isValid()) {
            formattedPhone = parsedPhone.formatInternational();
        } else {
            formattedPhone = phone;
        }
    }
    return formattedPhone;
}
