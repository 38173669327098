import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCat,
    faCow,
    faCrow,
    faDog,
    faDove,
    faDragon,
    faFish,
    faFrog,
    faHippo,
    faHorse,
    faOtter,
    faWorm
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const NotFound = () => {
    const {t} = useTranslation();

    const animalIcons = [
        faHippo,
        faOtter,
        faDog,
        faFish,
        faCow,
        faDragon,
        faWorm,
        faHorse,
        faFrog,
        faDove,
        faCrow,
        faCat
    ];

    const randomIcon = animalIcons[Math.floor(Math.random() * animalIcons.length)];

    return (
        <div className="d-flex justify-content-center align-items-center flex-grow-1">
            <div className="text-center">
                <FontAwesomeIcon icon={randomIcon} size="3x" style={{marginBottom: "8px"}}/>
                <div className="fw-bold text-size-xl">{t("oops")}</div>
                <div className="text-color-med">{t("notFound")}</div>
                <div className="text-color-med">{t("cheerUpAnimal")}</div>
                {/*<FontAwesomeIcon icon={faMapSigns} size="3x" style={{marginBottom: "8px"}}/>
                <div className="fw-bold text-size-xl">{t("oops")}</div>
                <div className="text-color-med">{t("notFound")}</div>*/}
            </div>
        </div>
    )
}

export { NotFound };
