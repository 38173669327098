import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ActionButton,
    Calendar,
    Card,
    colors,
    ConfidenceLevelSelector,
    EditableTitleValueLine,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    PageTitle,
    SearchableList,
    TitleValueLine,
    tooltipStyles
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse, requestTypes } from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { routes, routeWithParams } from "helpers/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faCircle,
    faCopy,
    faLinkSlash,
    faPencilAlt,
    faPlus,
    faSave,
    faTimes,
    faTrashAlt,
    faUndo
} from "@fortawesome/free-solid-svg-icons";
import { convertToPrettyDate, dateObjectToDateString } from "helpers/date";
import { assignmentPages, assignmentTypes } from "../Assignment";
import { DateRangePicker } from "react-date-range";
import { Tooltip } from "react-tooltip";
import { useHotkeys } from "react-hotkeys-hook";
import { authGroups } from "services/auth";
import { HistoryBackButton } from "components/utils/navigation";
import { useHistoryContext } from "contexts/HistoryProvider";
import useConfirm from "hooks/useConfirmModal";

const Deployment = ({authInfo}) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const {updateCurrentRouteName} = useHistoryContext();

    const {confirm, ConfirmModal} = useConfirm();

    const [loaded, setLoaded] = useState(false);
    const [assignmentsLoaded, setAssignmentsLoaded] = useState(false);
    const [sitesLoaded, setSitesLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const [deal, setDeal] = useState(null);
    const [confidenceLevel, setConfidenceLevel] = useState(false);
    const [editingConfidenceLevel, setEditingConfidenceLevel] = useState(false);
    const [loadedConfidenceLevel, setLoadedConfidenceLevel] = useState(true);

    const [travelStartDate, setTravelStartDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [travelEndDate, setTravelEndDate] = useState(new Date());

    useHotkeys("esc", () => navigate(routes.deployments.path));

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.deployment;
        createEmptyApiResponse(requestTypes.GET, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl(id)).then(apiRequestSuccess => {
            if (apiRequestSuccess) {
                setLoaded(true);
                setAssignmentsLoaded(true);
                setSitesLoaded(true);
            }
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (!!data) {
            if (!!data.summary && !!data.summary.name) updateCurrentRouteName(data.summary.name);
            setDeal(data.summary.deal.id);
            setConfidenceLevel(data.summary.confidenceLevel);
            setDatesToData();
        }
    }, [data]);

    const setDatesToData = () => {
        setStartDate(new Date(data.dates.startDate + "T00:00:00"));
        setEndDate(new Date(data.dates.endDate + "T00:00:00"));
        setTravelStartDate(new Date(data.dates.travelStartDate + "T00:00:00"));
        setTravelEndDate(new Date(data.dates.travelEndDate + "T00:00:00"));
        return true;
    };

    const copyDeployment = () => {
        navigate(routes.newDeployment.path, {
            state: {
                name: data.summary.name,
                deal: data.summary.deal.id,
                confidenceLevel: data.summary.confidenceLevel,
                address: data.location.address,
                city: data.location.city,
                state: data.location.state,
                zip: data.location.zip,
                country: data.location.country,
                travelStartDate: data.dates.travelStartDate,
                startDate: data.dates.startDate,
                endDate: data.dates.endDate,
                travelEndDate: data.dates.travelEndDate,
                staff: data.requirements.numStaff,
                calibratedRobots: data.requirements.numCalibratedRobots,
                uncalibratedRobots: data.requirements.numUncalibratedRobots,
                targets: data.requirements.numTargets,
            }
        })
    }

    const deleteDeployment = async () => {
        //return updateDeployment("deleted", true);
        const confirmation = await confirm(t("confirmation"), t("confirmDeploymentDeletion"), t("yes"), t("no"));
        if (!confirmation) return;
        setAssignmentsLoaded(false);
        const updateDeploymentApiEndpoint = apiEndpoints.deployment;
        return apiRequest(setData, setError, requestTypes.DELETE, updateDeploymentApiEndpoint.constructUrl(id)).then(apiRequestSuccess => {
            setAssignmentsLoaded(true);
            return apiRequestSuccess;
        });
    }

    const restoreDeployment = () => {
        return updateDeployment("deleted", false);
    }

    const updateDeployment = async (field, value) => {
        return await apiRequest(setData, setError, requestTypes.PUT, apiEndpoints.deployment.constructUrl(id), {[field]: value});
    };

    useEffect(() => {
        if (travelStartDate > startDate) setStartDate(travelStartDate);
    }, [travelStartDate]);
    useEffect(() => {
        if (startDate > endDate) setEndDate(startDate);
        if (startDate < travelStartDate) setTravelStartDate(startDate);
    }, [startDate]);
    useEffect(() => {
        if (endDate > travelEndDate) setTravelEndDate(endDate);
        if (endDate < startDate) setStartDate(endDate)
    }, [endDate]);
    useEffect(() => {
        if (travelEndDate < endDate) setEndDate(travelEndDate);
    }, [travelEndDate]);

    const handleSelect = (ranges) => {
        if (ranges.travelDates) {
            const newTravelStartDate = ranges.travelDates.startDate;
            const newTravelEndDate = ranges.travelDates.endDate;
            setTravelStartDate(newTravelStartDate);
            setTravelEndDate(newTravelEndDate);
        }
        if (ranges.dates) {
            const newStartDate = ranges.dates.startDate;
            const newEndDate = ranges.dates.endDate;
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        }
    };

    const updateDates = async () => {
        const updateDeploymentApiEndpoint = apiEndpoints.deployment;
        let updateAssignments = false;
        const confirmation = await confirm(t("confirmation"), t("shouldUpdateAssignments"), t("yes"), t("no"));
        if (confirmation) updateAssignments = true;
        return await apiRequest(
            setData,
            setError,
            requestTypes.PUT,
            updateDeploymentApiEndpoint.constructUrl(id),
            {
                travelStartDate: dateObjectToDateString(travelStartDate),
                startDate: dateObjectToDateString(startDate),
                endDate: dateObjectToDateString(endDate),
                travelEndDate: dateObjectToDateString(travelEndDate),
                updateAssignments: updateAssignments
            }
        );
    };

    const ranges = [
        {
            startDate: travelStartDate,
            endDate: travelEndDate,
            key: "travelDates",
            color: "#637378",
            autoFocus: true
        },
        {
            startDate: startDate,
            endDate: endDate,
            key: "dates",
            color: "#f25c07",
            autoFocus: true
        },
    ];

    const sitesTableHeaders = {
        name: t("name"),
        location: t("location"),
        unlink: null
    }

    const processSites = (sites) => {
        return sites.map(site => {
            return {
                name: (<LinkWithParams baseUrl={routes.site.path} params={{id: site.id}} text={site.name}/>),
                location: site.location,
                unlink: (
                    <div className="align-items-center d-flex flex-nowrap justify-content-end h-100">
                        <FontAwesomeIcon icon={faLinkSlash} className="clickable text-color-accent"
                                         onClick={() => unlinkSite(site.id)}/>
                    </div>
                )
            };
        });
    };

    const unlinkSite = async (siteId) => {
        const confirmation = await confirm(t("confirmation"), t("confirmSiteUnlinking"), t("yes"), t("no"));
        if (!confirmation) return;
        setSitesLoaded(false);
        apiRequest(
            () => {
            },
            setError,
            requestTypes.DELETE,
            apiEndpoints.linkDeploymentAndSite.constructUrl(id, siteId)
        ).then(deleteSuccess => {
            apiRequest(setData, setError, requestTypes.GET, apiEndpoints.deployment.constructUrl(id)).then(apiRequestSuccess => {
                if (deleteSuccess && apiRequestSuccess) setSitesLoaded(true);
            })
        });
    }

    const assignmentsTableHeaders = {
        name: t("name"),
        travelStartDate: t("travelStartDate"),
        startDate: t("startDate"),
        endDate: t("endDate"),
        travelEndDate: t("travelEndDate"),
        action: "",
    };

    const processRobotAssignments = (assignments) => {
        return assignments.map(assignment => {
            return {
                name: (<LinkWithParams baseUrl={routes.robot.path} params={{id: assignment.assignee.id}}
                                       text={assignment.assignee.name}/>),
                travelStartDate: convertToPrettyDate(assignment.travelStartDate),
                startDate: convertToPrettyDate(assignment.startDate),
                endDate: convertToPrettyDate(assignment.endDate),
                travelEndDate: convertToPrettyDate(assignment.travelEndDate),
                action: (
                    <div className="align-items-center d-flex flex-nowrap justify-content-end h-100">
                        <LinkWithParams baseUrl={routes.assignment.path}
                                        params={{page: assignmentPages.update, id: assignment.id}}>
                            <FontAwesomeIcon icon={faPencilAlt} className="clickable text-color-accent pe-4"/>
                        </LinkWithParams>
                        <a>
                            <FontAwesomeIcon icon={faTrashAlt} className="clickable text-color-accent"
                                             onClick={() => deleteAssignment(assignment.id)}/>
                        </a>
                    </div>
                )
            };
        });
    };

    const processStaffAssignments = (assignments) => {
        return assignments.map(assignment => {
            return {
                name: (<LinkWithParams baseUrl={routes.operator.path} params={{id: assignment.assignee.id}}
                                       text={assignment.assignee.name}/>),
                travelStartDate: convertToPrettyDate(assignment.travelStartDate),
                startDate: convertToPrettyDate(assignment.startDate),
                endDate: convertToPrettyDate(assignment.endDate),
                travelEndDate: convertToPrettyDate(assignment.travelEndDate),
                action: (
                    <div className="align-items-center d-flex flex-nowrap justify-content-end h-100">
                        <LinkWithParams baseUrl={routes.assignment.path}
                                        params={{page: assignmentPages.update, id: assignment.id}}>
                            <FontAwesomeIcon icon={faPencilAlt} className="clickable text-color-accent pe-4"/>
                        </LinkWithParams>
                        <a>
                            <FontAwesomeIcon icon={faTrashAlt} className="clickable text-color-accent"
                                             onClick={() => deleteAssignment(assignment.id)}/>
                        </a>
                    </div>
                )
            };
        });
    };

    const deleteAssignment = async (assignmentId) => {
        const confirmation = await confirm(t("confirmation"), t("confirmAssignmentDeletion"), t("yes"), t("no"));
        if (!confirmation) return;
        setAssignmentsLoaded(false);
        apiRequest(
            () => {
            },
            setError,
            requestTypes.DELETE,
            apiEndpoints.assignment.constructUrl(assignmentId)
        ).then(deleteSuccess => {
            apiRequest(setData, setError, requestTypes.GET, apiEndpoints.deployment.constructUrl(id)).then(apiRequestSuccess => {
                if (deleteSuccess && apiRequestSuccess) setAssignmentsLoaded(true);
            })
        });
    }

    const assignmentsToCalendarRows = (assignments) => {
        return assignments.reduce((acc, item) => {
            acc[item.assignee.id] = item.assignee.name;
            return acc;
        }, {});
    }

    const assignmentsToCalendarEvents = (assignments) => {
        return assignments.map(assignment => {
            return {
                name: t("assigned"),
                travelStartDate: assignment.travelStartDate,
                startDate: assignment.startDate,
                endDate: assignment.endDate,
                travelEndDate: assignment.travelEndDate,
                color: colors.accent,
                row: assignment.assignee.id,
                url: routeWithParams(routes.assignment.path, {page: assignmentPages.update, id: assignment.id})
            };
        });
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <ConfirmModal/>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <HistoryBackButton className="mb-1"/>
                    {loaded ? (
                        <div className="align-items-center d-flex">
                            {data.summary.name}
                            {data.summary.deleted && (
                                <div className="badge badge-accent ms-2">{t("DELETED")}</div>
                            )}
                        </div>
                    ) : (
                        <LoadingSpinner size={46}/>
                    )}
                </div>
                <div className="flex-grow-1"></div>
                {loaded && (
                    data.summary.deleted ? (
                        <>
                            <div className="clickable text-end text-decoration-none">
                                <FontAwesomeIcon
                                    data-tooltip-id="restore-deployment-tooltip"
                                    icon={faUndo}
                                    className="text-color-accent"
                                    onClick={() => restoreDeployment()}
                                />
                            </div>
                            <Tooltip
                                id={`restore-deployment-tooltip`}
                                place="left"
                                content={t("restoreDeployment")}
                                style={tooltipStyles}
                            />
                        </>
                    ) : (
                        <>
                            <div className="clickable text-end text-decoration-none pe-4">
                                <FontAwesomeIcon
                                    data-tooltip-id="copy-deployment-tooltip"
                                    icon={faCopy}
                                    className="text-color-accent"
                                    onClick={() => copyDeployment()}
                                />
                            </div>
                            <Tooltip
                                id={`copy-deployment-tooltip`}
                                place="left"
                                content={t("copyDeployment")}
                                style={tooltipStyles}
                            />
                            <div className="clickable text-end text-decoration-none">
                                <FontAwesomeIcon
                                    data-tooltip-id="delete-deployment-tooltip"
                                    icon={faTrashAlt}
                                    className="text-color-accent"
                                    onClick={() => deleteDeployment()}
                                />
                            </div>
                            <Tooltip
                                id={`delete-deployment-tooltip`}
                                place="left"
                                content={t("deleteDeployment")}
                                style={tooltipStyles}
                            />
                        </>
                    )
                )}
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("summary")}</div>
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("name")}:`}
                            value={data.summary.name}
                            className="mt-2"
                            onSave={async (value) => {
                                return await updateDeployment(`name`, value);
                            }}
                        />
                        {
                            !!data.summary.deal.id && (
                                <TitleValueLine
                                    loaded={loaded}
                                    title={`${t("deal")}:`}
                                    value={
                                        <LinkWithParams baseUrl={routes.deal.path}
                                                        params={{id: data.summary.deal.id}}
                                                        text={data.summary.deal.name}/>
                                    }
                                />
                            )
                        }
                        <div className="d-flex">
                            <div className="fw-bold me-1">{`${t("confidenceLevel")}:`}</div>
                            {loaded ? (
                                editingConfidenceLevel || !loadedConfidenceLevel ? (
                                    <>
                                        <div className="flex-grow-1 text-color-med">
                                            <ConfidenceLevelSelector loaded={loadedConfidenceLevel}
                                                                     confidenceLevel={confidenceLevel}
                                                                     setConfidenceLevel={setConfidenceLevel}/>
                                        </div>
                                        <div className="text-color-accent text-end">
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                onClick={() => {
                                                    if (!loadedConfidenceLevel) return;
                                                    setLoadedConfidenceLevel(false);
                                                    setEditingConfidenceLevel(false);
                                                    updateDeployment("confidenceLevel", confidenceLevel).then(() => {
                                                        setLoadedConfidenceLevel(true);
                                                    })
                                                }}
                                                className="clickable pe-2"
                                            />
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                onClick={() => {
                                                    setEditingConfidenceLevel(false);
                                                    setConfidenceLevel(data.summary.confidenceLevel);
                                                }}
                                                className="clickable"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            onClick={() => setEditingConfidenceLevel(true)}
                                            className="clickable flex-grow-1 text-color-med"
                                        >
                                            {t(`api:deployment_confidence_level_list.${data.summary.confidenceLevel}`)}
                                        </div>
                                        <div className="text-color-accent text-end">
                                            <FontAwesomeIcon
                                                icon={faPencilAlt}
                                                onClick={() => setEditingConfidenceLevel(true)}
                                                className="clickable"
                                            />
                                        </div>
                                    </>
                                )
                            ) : (
                                <LoadingSpinner/>
                            )}
                        </div>
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("location")}</div>
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("address")}:`}
                            value={data.location.address}
                            className="mt-2"
                            onSave={async (value) => {
                                return await updateDeployment(`address`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("city")}:`}
                            value={data.location.city}
                            onSave={async (value) => {
                                return await updateDeployment(`city`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("state")}:`}
                            value={data.location.state}
                            onSave={async (value) => {
                                return await updateDeployment(`state`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("zip")}:`}
                            value={data.location.zip}
                            onSave={async (value) => {
                                return await updateDeployment(`zip`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("country")}:`}
                            value={data.location.country}
                            onSave={async (value) => {
                                return await updateDeployment(`country`, value);
                            }}
                        />
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("requirements")}</div>
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("staff")}:`}
                            value={data.requirements.numStaff}
                            type="number"
                            inputMode="numeric"
                            inputAttributes={{min: 0}}
                            className="mt-2"
                            onSave={async (value) => {
                                return await updateDeployment(`numStaff`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("calibratedRobots")}:`}
                            value={data.requirements.numCalibratedRobots}
                            type="number"
                            inputMode="numeric"
                            inputAttributes={{min: 0}}
                            onSave={async (value) => {
                                return await updateDeployment(`numCalibratedRobots`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("uncalibratedRobots")}:`}
                            value={data.requirements.numUncalibratedRobots}
                            type="number"
                            inputMode="numeric"
                            inputAttributes={{min: 0}}
                            onSave={async (value) => {
                                return await updateDeployment(`numUncalibratedRobots`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("targets")}:`}
                            value={data.requirements.numTargets}
                            type="number"
                            inputMode="numeric"
                            inputAttributes={{min: 0}}
                            onSave={async (value) => {
                                return await updateDeployment(`numTargets`, value);
                            }}
                        />
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="text-center">
                            <div className="fw-bold text-size-lg my-2">{t("dates")}</div>
                            <DateRangePicker
                                onChange={handleSelect}
                                ranges={ranges}
                                shownDate={new Date()}
                                staticRanges={[]}
                                inputRanges={[]}
                                retainEndDateOnFirstSelection={true}
                            />
                            <div className="align-items-center d-flex justify-content-center mb-2 w-100">
                                <FontAwesomeIcon icon={faCircle}
                                                 className="text-color-med"/>&nbsp;{t("traveling")}
                                <FontAwesomeIcon icon={faCircle}
                                                 className="text-color-accent ps-4"/>&nbsp;{t("deployed")}
                            </div>
                            <div className="d-flex justify-content-center mt-3 mb-1">
                                <ActionButton
                                    defaultIcon={faSave}
                                    disabled={(
                                        dateObjectToDateString(travelStartDate) === data.dates.travelStartDate &&
                                        dateObjectToDateString(startDate) === data.dates.startDate &&
                                        dateObjectToDateString(endDate) === data.dates.endDate &&
                                        dateObjectToDateString(travelEndDate) === data.dates.travelEndDate
                                    )}
                                    className="me-4"
                                    size={32}
                                    action={() => updateDates()}
                                    tooltipText={t("save")}
                                />
                                <ActionButton
                                    defaultIcon={faUndo}
                                    disabled={(
                                        dateObjectToDateString(travelStartDate) === data.dates.travelStartDate &&
                                        dateObjectToDateString(startDate) === data.dates.startDate &&
                                        dateObjectToDateString(endDate) === data.dates.endDate &&
                                        dateObjectToDateString(travelEndDate) === data.dates.travelEndDate
                                    )}
                                    size={32}
                                    action={() => setDatesToData()}
                                    tooltipText={t("undoChanges")}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-12">
                    {(authInfo.userGroups.includes(authGroups.operationsAdmins) || authInfo.userGroups.includes(authGroups.businessAdmins)) && !!data.revenue && (
                        <Card>
                            <div className="fw-bold text-size-lg">{t("revenue")}</div>
                            <EditableTitleValueLine
                                loaded={loaded}
                                title={`${t("revenue")}:`}
                                value={data.revenue.revenue}
                                type="number"
                                inputMode="numeric"
                                inputAttributes={{min: 0}}
                                className="mt-2"
                                onSave={async (value) => {
                                    return await updateDeployment(`revenue`, value);
                                }}
                            />
                            <EditableTitleValueLine
                                loaded={loaded}
                                title={`${t("invoiced")}:`}
                                value={data.revenue.invoiced}
                                type="checkbox"
                                inputAttributes={{min: 0}}
                                onSave={async (value) => {
                                    return await updateDeployment(`invoiced`, !!value);
                                }}
                            />
                            <EditableTitleValueLine
                                loaded={loaded}
                                title={`${t("paid")}:`}
                                value={data.revenue.paid}
                                type="checkbox"
                                inputAttributes={{min: 0}}
                                onSave={async (value) => {
                                    return await updateDeployment(`paid`, !!value);
                                }}
                            />
                        </Card>
                    )}
                    <Card>
                        <SearchableList
                            loaded={loaded && sitesLoaded}
                            title={t("sites")}
                            columnNames={sitesTableHeaders}
                            data={processSites(data.sites)}
                        />
                        {!data.summary.deleted && (
                            <LinkWithParams
                                baseUrl={routes.linkSiteToDeployment.path}
                                params={{deploymentId: id}}
                            >
                                <div
                                    className="clickable d-flex justify-content-center align-items-center text-color-accent">
                                    <div className="pe-1">
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </div>
                                    <div className="text-decoration-none">{t("linkSite")}</div>
                                </div>
                            </LinkWithParams>
                        )}
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded && assignmentsLoaded}
                            title={t("robots")}
                            columnNames={assignmentsTableHeaders}
                            data={processRobotAssignments(data.robotAssignments)}
                        />
                        {!data.summary.deleted && (
                            <LinkWithParams
                                baseUrl={routes.assignment.path}
                                params={{page: assignmentPages.new, id: id, type: assignmentTypes.robots}}
                            >
                                <div
                                    className="clickable d-flex justify-content-center align-items-center text-color-accent">
                                    <div className="pe-1">
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </div>
                                    <div className="text-decoration-none">{t("assignRobot")}</div>
                                </div>
                            </LinkWithParams>
                        )}
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded && assignmentsLoaded}
                            title={t("staff")}
                            columnNames={assignmentsTableHeaders}
                            data={processStaffAssignments(data.staffAssignments)}
                        />
                        {!data.summary.deleted && (
                            <LinkWithParams
                                baseUrl={routes.assignment.path}
                                params={{page: assignmentPages.new, id: id, type: assignmentTypes.operators}}
                            >
                                <div
                                    className="clickable d-flex justify-content-center align-items-center accentext-color-t">
                                    <div className="pe-1">
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </div>
                                    <div className="text-decoration-none">{t("assignStaff")}</div>
                                </div>
                            </LinkWithParams>
                        )}
                    </Card>
                </div>
                {
                    !!data && !!data.robotAssignments && !!data.robotAssignments.length &&
                    <div className="col-12 col-lg-6">
                        <Card>
                            <div className="fw-bold text-size-lg mb-3">{t("robotSchedule")}</div>
                            <Calendar
                                loaded={loaded && assignmentsLoaded}
                                startDate={data.dates.travelStartDate}
                                endDate={data.dates.travelEndDate}
                                rows={assignmentsToCalendarRows(data.robotAssignments)}
                                rowLinkFunction={(id, name) => <LinkWithParams baseUrl={routes.robot.path}
                                                                               params={{id: id}} text={name}/>}
                                events={assignmentsToCalendarEvents(data.robotAssignments)}/>
                        </Card>
                    </div>
                }
                {
                    !!data && !!data.staffAssignments && !!data.staffAssignments.length &&
                    <div className="col-12 col-lg-6">
                        <Card>
                            <div className="fw-bold text-size-lg mb-3">{t("staffSchedule")}</div>
                            <Calendar
                                loaded={loaded && assignmentsLoaded}
                                startDate={data.dates.travelStartDate}
                                endDate={data.dates.travelEndDate}
                                rows={assignmentsToCalendarRows(data.staffAssignments)}
                                rowLinkFunction={(id, name) => <LinkWithParams baseUrl={routes.operator.path}
                                                                               params={{id: id}} text={name}/>}
                                events={assignmentsToCalendarEvents(data.staffAssignments)}/>
                        </Card>
                    </div>
                }
            </div>
        </>
    )
}

export { Deployment };