import React from "react";
import PropTypes from "prop-types";
import { invisibleInputStyles } from "components/utils/ui";

const TitleValueInput = ({
                             title,
                             value,
                             setValue,
                             type = `text`,
                             inputMode = null,
                             inputAttributes = {},
                             className = ``,
                         }) => {

    const handleChange = (event) => {
        const inputValue = event.target.value;
        if (type === `number`) {
            if (inputValue === "") {
                setValue(""); // Allow the input to be cleared
            } else {
                setValue(Number(inputValue));
            }
        } else {
            setValue(inputValue);
        }
    };

    const handleKeyDown = (event) => {
        if ((event.key === "Backspace" || event.key === "Delete") && value === "") {
            event.target.type = "text"; // Temporarily set type to text to allow clearing
        }
    };

    const handleBlur = (event) => {
        if (event.target.type === "text" && type === "number") {
            event.target.type = "number"; // Revert type back to number on blur
        }
    };

    return (
        <div className={`d-flex w-100 ${className}`}>
            <div className={`fw-bold me-1 text-nowrap`}>{title}</div>
            <div className="align-items-center d-flex flex-grow-1 pe-2 text-color-med">
                <input
                    type={type}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    style={{
                        ...invisibleInputStyles,
                        width: type === `date` ? `` : `100%`,
                        height: type === `date` ? `24px` : ``
                    }}
                    {...inputAttributes}
                />
            </div>
        </div>
    );
};

TitleValueInput.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
    ]),
    setValue: PropTypes.func,
    type: PropTypes.string,
    inputAttributes: PropTypes.object,
    className: PropTypes.string,
};

export { TitleValueInput };
