import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { redirectToLogin } from "services/auth";
import { useNavigate } from "react-router-dom";
import { routes } from "helpers/routes";

const LogoutSuccess = ({authInfo}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (authInfo && authInfo.authenticated) navigate(routes.home.path);
    }, [authInfo.authenticated]);

    return (
        <div className="d-flex justify-content-center align-items-center flex-grow-1">
            <div className="text-center">
                <FontAwesomeIcon icon={faCheckCircle} size="3x" style={{marginBottom: "8px"}}/>
                <div className="fw-bold text-size-xl">{t("Success")}</div>
                <div className="text-color-med">{t("successfullyLoggedOut")}</div>
                <button className="border-0 bg-accent mt-3 px-3 py-2 rounded text-color-white"
                        onClick={() => redirectToLogin(true)}>{t("logBackIn")}</button>
            </div>
        </div>
    )
}

export { LogoutSuccess };
