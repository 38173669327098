const getCurrentUrl = () => {
    return `${window.location.origin}${window.location.pathname}${window.location.search}${window.location.hash}`;
}

const getCurrentUri = () => {
    return `${window.location.pathname}${window.location.search}${window.location.hash}`;
}

const getCurrentOrigin = () => {
    return window.location.origin;
}

export { getCurrentUrl, getCurrentUri, getCurrentOrigin };