import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    ExternalLink,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    PageTitle,
    SearchableList,
    TitleValueLine
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse, requestTypes } from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "helpers/routes";
import { convertToPrettyDateWithYear, dateObjectToDateString, getLastSundayDateObject } from "helpers/date";
import { formatPhoneNumber } from "helpers/phone";
import { useHotkeys } from "react-hotkeys-hook";
import { HistoryBackButton } from "components/utils/navigation";
import { useHistoryContext } from "contexts/HistoryProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkSlash, faPlus } from "@fortawesome/free-solid-svg-icons";
import useConfirm from "hooks/useConfirmModal";

const Deal = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const {updateCurrentRouteName} = useHistoryContext();

    const {confirm, ConfirmModal} = useConfirm();

    const [loaded, setLoaded] = useState(false);
    const [sitesLoaded, setSitesLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const lastSunday = dateObjectToDateString(getLastSundayDateObject());

    useHotkeys("esc", () => navigate(routes.deals.path));

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.deal;
        createEmptyApiResponse(requestTypes.GET, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl(id)).then(apiRequestSuccess => {
            if (apiRequestSuccess) {
                setLoaded(true);
                setSitesLoaded(true);
            }
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (!!data && !!data.summary && !!data.summary.name) updateCurrentRouteName(data.summary.name);
    }, [data]);

    const dealContactsTableHeaders = {
        name: t("name"),
        phone: t("phone"),
        email: t("email")
    }

    const processDealContacts = (contacts) => {
        return contacts.map(contact => {
            const formattedPhone = formatPhoneNumber(contact.phone);
            return {
                name: contact.name,
                email: contact.email ? (<a className="clickable text-color-accent"
                                           href={`mailto:${contact.email}`}>{contact.email}</a>) : "",
                phone: formattedPhone ? (<a className="clickable text-color-accent"
                                            href={`tel:${formattedPhone}`}>{formattedPhone}</a>) : "",
            };
        });
    };

    const dealCompaniesTableHeaders = {
        name: t("name"),
        domain: t("website"),
        phone: t("phone")
    }

    const processDealCompanies = (companies) => {
        return companies.map(company => {
            const formattedPhone = formatPhoneNumber(company.phone);
            const formattedDomain = company.domain ? (company.domain.startsWith(`http://`) || company.domain.startsWith(`https://`))
                    ? company.domain
                    : `http://${company.domain}`
                : ``;
            return {
                name: company.name,
                domain: formattedDomain ? (
                    <a className="clickable text-color-accent"
                       href={formattedDomain} target="_blank" rel="noopener noreferrer">
                        {company.domain}
                    </a>
                ) : "",
                phone: formattedPhone ? (
                    <a className="clickable text-color-accent"
                       href={`tel:${formattedPhone}`}>
                        {formattedPhone}
                    </a>
                ) : "",
            };
        });
    };


    /*const processCalendarData = (calendarData) => {
        return calendarData.map(e => styleCalendarData(e));
    };

    const deploymentsTableHeaders = {
        name: t("name"),
        travelDates: t("travelDates"),
        location: t("location"),
        robots: t("robots"),
        staff: t("staff"),
        foremanAssigned: t("foremanAssigned"),
        confidenceLevel: t("confidence")
    }

    const processDeployments = (deployments) => {
        return deployments.map(deployment => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.deployment.path} params={{id: deployment.id}}
                                    text={deployment.name} key={uniqueId()}/>),
                travelDates: `${convertToPrettyDate(deployment.travelStartDate)}-${convertToPrettyDate(deployment.travelEndDate)}`,
                location: deployment.location,
                robots: (() => {
                    const robots = deployment.robots.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.robot.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return (
                        <div>
                            {deployment.robotsAssigned}/{deployment.robotsRequired}{!!deployment.robots.length && (<>&nbsp;({robots})</>)}
                        </div>
                    );
                })(),
                staff: (() => {
                    const staff = deployment.staff.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(<LinkWithParams baseUrl={routes.operator.path} params={{id: e.id}} text={e.name}
                                                 key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return (
                        <div>
                            {deployment.staffAssigned}/{deployment.staffRequired}{!!deployment.staff.length && (<>&nbsp;({staff})</>)}
                        </div>
                    );
                })(),
                foremanAssigned: deployment.staffRequired === 0 ? t("na") : (deployment.foremanAssigned ? t("yes") : t("no")),
                confidenceLevel: t(`api:deployment_confidence_level_list.${deployment.confidenceLevel}`)
            };
        });
    };*/

    const sitesTableHeaders = {
        name: t("name"),
        location: t("location"),
        unlink: null
    }

    const processSites = (sites) => {
        return sites.map(site => {
            return {
                name: (<LinkWithParams baseUrl={routes.site.path} params={{id: site.id}} text={site.name}/>),
                location: site.location,
                unlink: (
                    <div className="align-items-center d-flex flex-nowrap justify-content-end h-100">
                        <FontAwesomeIcon icon={faLinkSlash} className="clickable text-color-accent"
                                         onClick={() => unlinkSite(site.id)}/>
                    </div>
                )
            };
        });
    };

    const unlinkSite = async (siteId) => {
        const confirmation = await confirm(t("confirmation"), t("confirmSiteUnlinking"), t("yes"), t("no"));
        if (!confirmation) return;
        setSitesLoaded(false);
        apiRequest(
            () => {
            },
            setError,
            requestTypes.DELETE,
            apiEndpoints.linkDealAndSite.constructUrl(id, siteId)
        ).then(deleteSuccess => {
            apiRequest(setData, setError, requestTypes.GET, apiEndpoints.deal.constructUrl(id)).then(apiRequestSuccess => {
                if (deleteSuccess && apiRequestSuccess) setSitesLoaded(true);
            })
        });
    }

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <ConfirmModal/>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <HistoryBackButton className="mb-1"/>
                    {loaded ? (
                        data.summary.name
                    ) : (
                        <LoadingSpinner size={46}/>
                    )}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("summary")}</div>
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("name")}:`}
                            value={data.summary.name}
                            className="mt-2"
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("layoutStartDate")}:`}
                            value={convertToPrettyDateWithYear(data.summary.layoutStartDate)}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("location")}:`}
                            value={data.summary.location}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("dealType")}:`}
                            value={data.summary.type}
                        />
                        <ExternalLink
                            href={data.summary.url}
                            text={t("viewInHubspot")}
                            className="mt-2"
                        />
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded}
                            title={t("dealContacts")}
                            columnNames={dealContactsTableHeaders}
                            data={processDealContacts(data.contacts)}
                        />
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded}
                            title={t("dealCompanies")}
                            columnNames={dealCompaniesTableHeaders}
                            data={processDealCompanies(data.companies)}
                        />
                    </Card>
                    <Card>
                        <SearchableList
                            loaded={loaded && sitesLoaded}
                            title={t("sites")}
                            columnNames={sitesTableHeaders}
                            data={processSites(data.sites)}
                        />
                        <LinkWithParams
                            baseUrl={routes.linkSiteToDeal.path}
                            params={{dealId: id}}
                        >
                            <div
                                className="clickable d-flex justify-content-center align-items-center text-color-accent">
                                <div className="pe-1">
                                    <FontAwesomeIcon icon={faPlus}/>
                                </div>
                                <div className="text-decoration-none">{t("linkSite")}</div>
                            </div>
                        </LinkWithParams>
                    </Card>
                </div>
            </div>
        </>
    )
}

export { Deal };