import PropTypes from "prop-types";

const copyToClipboard = (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text).catch(err => {
            console.error(`Failed to copy text: ${err}`);
            return false
        });
    } else {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "absolute";
        textArea.style.left = "-9999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand("copy");
        } catch (err) {
            console.error(`Fallback: Oops, unable to copy: ${err}`);
            return false;
        }
        document.body.removeChild(textArea);
    }
    return true;
};

copyToClipboard.propTypes = {
    text: PropTypes.string.isRequired,
};

export { copyToClipboard };
