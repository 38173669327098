export const robotNavigationMap = {
    MK1017: {next: "MK1018", prev: "MK1037"},
    MK1018: {next: "MK1020", prev: "MK1017"},
    MK1020: {next: "MK1021", prev: "MK1018"},
    MK1021: {next: "MK1022", prev: "MK1020"},
    MK1022: {next: "MK1023", prev: "MK1021"},
    MK1023: {next: "MK1024", prev: "MK1022"},
    MK1024: {next: "MK1025", prev: "MK1023"},
    MK1025: {next: "MK1026", prev: "MK1024"},
    MK1026: {next: "MK1027", prev: "MK1025"},
    MK1027: {next: "MK1028", prev: "MK1026"},
    MK1028: {next: "MK1029", prev: "MK1027"},
    MK1029: {next: "MK1030", prev: "MK1028"},
    MK1030: {next: "MK1031", prev: "MK1029"},
    MK1031: {next: "MK1032", prev: "MK1030"},
    MK1032: {next: "MK1033", prev: "MK1031"},
    MK1033: {next: "MK1034", prev: "MK1032"},
    MK1034: {next: "MK1035", prev: "MK1033"},
    MK1035: {next: "MK1036", prev: "MK1034"},
    MK1036: {next: "MK1037", prev: "MK1035"},
    MK1037: {next: "MK1017", prev: "MK1036"}
}