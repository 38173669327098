import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { copyToClipboard } from "helpers/clipboard";
import { LoadingSpinner, tooltipStyles } from "components/utils/ui";
import { downloadFile } from "helpers/download";
import { useTranslation } from "react-i18next";
import { uniqueId } from "helpers/random";

const successErrorDuration = 1;
const fadeDuration = 0.25;

const useIconTransition = (defaultIcon) => {
    const [loading, setLoading] = useState(false);
    const [icon, setIcon] = useState(defaultIcon);
    const [transitionPhase, setTransitionPhase] = useState("fade-in");

    const handleAction = async (action) => {
        setLoading(true);
        try {
            const result = await action();
            setLoading(false);
            if (result) {
                setIcon("success");
                setTimeout(() => {
                    setTransitionPhase("fade-out");
                    setTimeout(() => {
                        setIcon(defaultIcon);
                        setTransitionPhase("fade-in");
                    }, fadeDuration * 1000);
                }, successErrorDuration * 1000);
            } else {
                setIcon("error");
                setTimeout(() => {
                    setTransitionPhase("fade-out");
                    setTimeout(() => {
                        setIcon(defaultIcon);
                        setTransitionPhase("fade-in");
                    }, fadeDuration * 1000);
                }, successErrorDuration * 1000);
            }
        } catch (err) {
            setLoading(false);
            setIcon("error");
            setTimeout(() => {
                setTransitionPhase("fade-out");
                setTimeout(() => {
                    setIcon(defaultIcon);
                    setTransitionPhase("fade-in");
                }, fadeDuration * 1000);
            }, successErrorDuration * 1000);
        }
    };

    const iconStyle = {
        transition: `opacity ${fadeDuration}s ease-in-out`,
        opacity: transitionPhase === "fade-out" ? 0 : 1
    };

    return {loading, icon, handleAction, iconStyle};
};

const getIcon = (icon, defaultIcon) => {
    switch (icon) {
        case "success":
            return faCheck;
        case "error":
            return faTimes;
        default:
            return defaultIcon;
    }
};

const getColorClass = (icon, disabled) => {
    switch (icon) {
        case "success":
            return "text-color-accent";
        case "error":
            return "text-color-accent";
        default:
            if (disabled) return "text-color-light";
            return "text-color-accent";
    }
};

const ActionButton = ({action, disabled = false, defaultIcon, size = 16, className = "", tooltipText}) => {
    const {loading, icon, handleAction, iconStyle} = useIconTransition(defaultIcon);
    const [tooltipId, setTooltipId] = useState("");

    useEffect(() => {
        setTooltipId(`tooltip-${uniqueId()}`);
    }, []);

    if (loading) return (
        <>
            <div style={{width: size / 4}}/>
            <LoadingSpinner size={size} className={className}/>
            <div style={{width: size / 4}}/>
        </>
    );

    const currentIcon = getIcon(icon, defaultIcon);

    return (
        <>
            <FontAwesomeIcon
                data-tooltip-id={currentIcon === defaultIcon ? tooltipId : null}
                icon={currentIcon}
                className={`${disabled ? `not-clickable` : currentIcon === defaultIcon ? `clickable` : ``} ${className} ${getColorClass(icon, disabled)}`}
                style={{
                    ...iconStyle,
                    fontSize: `${size}px`
                }}
                onClick={() => {
                    if (disabled || currentIcon !== defaultIcon) return;
                    handleAction(action);
                }}
                fixedWidth
            />
            <Tooltip
                id={tooltipId}
                place="top"
                content={tooltipText}
                style={tooltipStyles}
            />
        </>
    );
};

const CopyButton = ({disabled = false, text, size = 16, className = ""}) => {
    const {t} = useTranslation();
    const copy = () => copyToClipboard(text);
    return <ActionButton action={copy} defaultIcon={faCopy} size={size} className={className} tooltipText={t("copy")}/>;
};

const DownloadButton = ({disabled = false, name, text, size = 16, className = ""}) => {
    const {t} = useTranslation();
    const download = () => downloadFile(text, name);
    return <ActionButton action={download} defaultIcon={faDownload} size={size} className={className}
                         tooltipText={t("download")}/>;
};

export { ActionButton, CopyButton, DownloadButton };
