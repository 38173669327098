import React from "react";
import { LoadingSpinner } from "components/utils/ui";

const FullPageLoading = () => (
    <div className="d-flex justify-content-center align-items-center flex-grow-1">
        <div>
            <LoadingSpinner size={64}/>
        </div>
    </div>
);

export { FullPageLoading };