import React, { useState } from "react";
import { ModalDialog } from "components/utils/ui";
import { useTranslation } from "react-i18next";

const useConfirm = () => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const [resolvePromise, setResolvePromise] = useState(null);
    const [options, setOptions] = useState({
        title: t("confirmation"),
        message: "",
        yesText: t("yes"),
        noText: t("no"),
    });

    const confirm = (title, message, yesText, noText) => {
        setOptions({ title, message, yesText, noText });
        setShow(true);
        return new Promise((resolve) => {
            setResolvePromise(() => resolve);
        });
    };

    const handleConfirm = () => {
        resolvePromise(true);
        setShow(false);
    };

    const handleClose = () => {
        resolvePromise(false);
        setShow(false);
    };

    const ConfirmModal = () => (
        <ModalDialog
            show={show}
            onClose={handleClose}
            onConfirm={handleConfirm}
            yesText={options.yesText}
            noText={options.noText}
            title={options.title}
            children={options.message}
        />
    );

    return { confirm, ConfirmModal };
};

export default useConfirm;
