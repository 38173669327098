import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    LoadingSpinner,
    PageTitle,
    RobotCalibrationStatusSelector,
    TitleValueInput,
    tooltipStyles
} from "components/utils/ui";
import { apiEndpoints, apiRequest, requestTypes } from "services/api";
import { routes, routeWithParams } from "helpers/routes";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { useHotkeys } from "react-hotkeys-hook";
import { HistoryBackButton } from "components/utils/navigation";

const UpdateRobotCalibrationStatus = ({authInfo}) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    const [calibrationStatus, setCalibrationStatus] = useState(null);
    const [notes, setNotes] = useState(null);
    const [printerCheck, setPrinterCheck] = useState(null);
    const [forwardDistancePairError1, setForwardDistancePairError1] = useState(null);
    const [forwardDistancePairError2, setForwardDistancePairError2] = useState(null);
    const [forwardShiftX, setForwardShiftX] = useState(null);
    const [forwardShiftY, setForwardShiftY] = useState(null);
    const [forwardShiftTheta, setForwardShiftTheta] = useState(null);
    const [backwardDistancePairError1, setBackwardDistancePairError1] = useState(null);
    const [backwardDistancePairError2, setBackwardDistancePairError2] = useState(null);
    const [backwardShiftX, setBackwardShiftX] = useState(null);
    const [backwardShiftY, setBackwardShiftY] = useState(null);
    const [backwardShiftTheta, setBackwardShiftTheta] = useState(null);
    const [pinwheel1, setPinwheel1] = useState(null);
    const [pinwheel2, setPinwheel2] = useState(null);
    const [pinwheel3, setPinwheel3] = useState(null);
    const [plusVarForwardX, setPlusVarForwardX] = useState(null);
    const [plusVarForwardY, setPlusVarForwardY] = useState(null);
    const [plusVarReverseX, setPlusVarReverseX] = useState(null);
    const [plusVarReverseY, setPlusVarReverseY] = useState(null);
    const [targetVerificationPort, setTargetVerificationPort] = useState(null);
    const [targetVerificationStarboard, setTargetVerificationStarboard] = useState(null);
    const [targetAdditionRangeX, setTargetAdditionRangeX] = useState(null);
    const [targetAdditionRangeY, setTargetAdditionRangeY] = useState(null);

    const [isUpdating, setIsUpdating] = useState(false);
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(undefined);

    useHotkeys("esc", () => navigate(routeWithParams(routes.robotCalibrationHistory.path, {id: id})));

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const update = () => {
        if (!calibrationStatus) {
            window.alert(t("calibrationStatusRequired"));
            return;
        }
        if (!id || !authInfo || !authInfo.email || !calibrationStatus) {
            window.alert(t("errorUpdatingStatus"));
            return;
        }
        setIsUpdating(true);
        apiRequest(
            setData,
            setError,
            requestTypes.POST,
            apiEndpoints.updateRobotCalibrationStatus.constructUrl(),
            {
                robotId: id,
                userId: authInfo.email,
                notes: notes,
                calibrationStatus: calibrationStatus,
                forwardDistancePairError1: forwardDistancePairError1,
                forwardDistancePairError2: forwardDistancePairError2,
                forwardShiftX: forwardShiftX,
                forwardShiftY: forwardShiftY,
                forwardShiftTheta: forwardShiftTheta,
                backwardDistancePairError1: backwardDistancePairError1,
                backwardDistancePairError2: backwardDistancePairError2,
                backwardShiftX: backwardShiftX,
                backwardShiftY: backwardShiftY,
                backwardShiftTheta: backwardShiftTheta,
                pinwheel1: pinwheel1,
                pinwheel2: pinwheel2,
                pinwheel3: pinwheel3,
                plusVarForwardX: plusVarForwardX,
                plusVarForwardY: plusVarForwardY,
                plusVarReverseX: plusVarReverseX,
                plusVarReverseY: plusVarReverseY,
                targetVerificationPort: targetVerificationPort,
                targetVerificationStarboard: targetVerificationStarboard,
                targetAdditionRangeX: targetAdditionRangeX,
                targetAdditionRangeY: targetAdditionRangeY,
                printerCheck: printerCheck
            }
        ).then(apiRequestSuccess => {
            if (!apiRequestSuccess) alert(t("errorUpdatingStatus"));
            setIsUpdating(false);
        });
    }

    useEffect(() => {
        if (data === undefined || !data.robotId) return;
        navigate(routeWithParams(routes.robotCalibrationHistory.path, {id: data.robotId}));
    }, [data]);

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <HistoryBackButton className="mb-1"/>
                    {t("updateRobotCalibrationStatus", {robot: id})}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("info")}</div>
                        <div className="d-flex mt-2">
                            <div className="fw-bold me-1">{`${t("calibrationStatus")}:`}</div>
                            <div className="text-color-med flex-grow-1">
                                <RobotCalibrationStatusSelector loaded={true} robotCalibrationStatus={calibrationStatus}
                                                                setRobotCalibrationStatus={setCalibrationStatus}/>
                            </div>
                            {!calibrationStatus && (
                                <>
                                    <FontAwesomeIcon icon={faExclamationCircle} className="text-color-accent"
                                                     data-tooltip-id="calibration-status-required-tooltip"/>
                                    <Tooltip
                                        id={`calibration-status-required-tooltip`}
                                        place="left"
                                        content={t("calibrationStatusRequired")}
                                        style={tooltipStyles}
                                    />
                                </>
                            )}
                        </div>
                        <TitleValueInput
                            title={`${t("notes")}:`}
                            value={notes}
                            setValue={setNotes}
                        />
                        <TitleValueInput
                            title={`${t("printerCheck")}:`}
                            value={printerCheck}
                            setValue={setPrinterCheck}
                        />
                        <div className="align-items-center d-flex fst-italic text-color-med mt-2">
                            <FontAwesomeIcon icon={faCircleInfo} className="pe-1 text-size-xs"/>
                            {t("pleaseDoubleCheckAllInfo")}
                        </div>
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("valuesFromCalibration")}</div>
                        <TitleValueInput
                            title={`${t("forwardDistancePairError1")}:`}
                            value={forwardDistancePairError1}
                            setValue={setForwardDistancePairError1}
                            type="number"
                            inputMode="decimal"
                            className="mt-2"
                        />
                        <TitleValueInput
                            title={`${t("forwardDistancePairError2")}:`}
                            value={forwardDistancePairError2}
                            setValue={setForwardDistancePairError2}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("forwardShiftX")}:`}
                            value={forwardShiftX}
                            setValue={setForwardShiftX}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("forwardShiftY")}:`}
                            value={forwardShiftY}
                            setValue={setForwardShiftY}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("forwardShiftTheta")}:`}
                            value={forwardShiftTheta}
                            setValue={setForwardShiftTheta}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("backwardDistancePairError1")}:`}
                            value={backwardDistancePairError1}
                            setValue={setBackwardDistancePairError1}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("backwardDistancePairError2")}:`}
                            value={backwardDistancePairError2}
                            setValue={setBackwardDistancePairError2}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("backwardShiftX")}:`}
                            value={backwardShiftX}
                            setValue={setBackwardShiftX}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("backwardShiftY")}:`}
                            value={backwardShiftY}
                            setValue={setBackwardShiftY}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("backwardShiftTheta")}:`}
                            value={backwardShiftTheta}
                            setValue={setBackwardShiftTheta}
                            type="number"
                            inputMode="decimal"
                        />
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("valuesFromPerformanceCheck")}</div>
                        <TitleValueInput
                            title={`${t("pinwheel1")}:`}
                            value={pinwheel1}
                            setValue={setPinwheel1}
                            type="number"
                            inputMode="decimal"
                            className="mt-2"
                        />
                        <TitleValueInput
                            title={`${t("pinwheel2")}:`}
                            value={pinwheel2}
                            setValue={setPinwheel2}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("pinwheel3")}:`}
                            value={pinwheel3}
                            setValue={setPinwheel3}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("plusVarForwardX")}:`}
                            value={plusVarForwardX}
                            setValue={setPlusVarForwardX}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("plusVarForwardY")}:`}
                            value={plusVarForwardY}
                            setValue={setPlusVarForwardY}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("plusVarReverseX")}:`}
                            value={plusVarReverseX}
                            setValue={setPlusVarReverseX}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("plusVarReverseY")}:`}
                            value={plusVarReverseY}
                            setValue={setPlusVarReverseY}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("targetVerificationPort")}:`}
                            value={targetVerificationPort}
                            setValue={setTargetVerificationPort}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("targetVerificationStarboard")}:`}
                            value={targetVerificationStarboard}
                            setValue={setTargetVerificationStarboard}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("targetAdditionRangeX")}:`}
                            value={targetAdditionRangeX}
                            setValue={setTargetAdditionRangeX}
                            type="number"
                            inputMode="decimal"
                        />
                        <TitleValueInput
                            title={`${t("targetAdditionRangeY")}:`}
                            value={targetAdditionRangeY}
                            setValue={setTargetAdditionRangeY}
                            type="number"
                            inputMode="decimal"
                        />
                    </Card>
                </div>
                <div className="col-12 my-2 my-lg-3 d-flex justify-content-center">
                    {isUpdating ? (
                        <LoadingSpinner size={38}/>
                    ) : (
                        <>
                            <div className="col-6 text-end">
                                <button type="button" className="btn bg-accent me-3 text-white"
                                        onClick={() => update()} disabled={!calibrationStatus}>
                                    {t("save")}
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn bg-med ms-3 text-white" onClick={() => {
                                    navigate(routeWithParams(routes.robot.path, {id: id}));
                                }}>
                                    {t("cancel")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export { UpdateRobotCalibrationStatus };
