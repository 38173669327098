import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapSigns } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const FileNotFound = () => {
    const {t} = useTranslation();
    return (
        <div className="d-flex justify-content-center align-items-center flex-grow-1">
            <div className="text-center">
                <FontAwesomeIcon icon={faMapSigns} size="3x" style={{marginBottom: "8px"}}/>
                <div className="fw-bold text-size-xl">{t("oops")}</div>
                <div className="text-color-med">{t("fileNotFound")}</div>
            </div>
        </div>
    )
}

export { FileNotFound };
