import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiEndpoints, apiRequest, createEmptyApiResponse, requestTypes } from "services/api";
import { Card, FullPageLoading, LinkWithParams, PageTitle, SearchableList, tooltipStyles } from "components/utils/ui";
import { routes } from "helpers/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { convertToPrettyDateWithYear } from "helpers/date";
import { Tooltip } from "react-tooltip";

const Deals = () => {
    const {t} = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.deals;
        createEmptyApiResponse(requestTypes.GET, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl()).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const refreshDeals = () => {
        setLoaded(false);
        const getDataApiEndpoint = apiEndpoints.refreshDeals;
        createEmptyApiResponse(requestTypes.GET, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl()).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }

    const dealsTableHeaders = {
        name: t("name"),
        layoutStartDate: t("layoutStartDate"),
        location: t("location"),
        type: t("dealType")
    }

    const processDeals = (deals) => {
        return deals.map(deal => {
            return {
                name: (<LinkWithParams baseUrl={routes.deal.path} params={{id: deal.id}} text={deal.name}/>),
                layoutStartDate: convertToPrettyDateWithYear(deal.layoutStartDate),
                location: deal.location,
                type: deal.type
            };
        });
    };

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    {t("deals")}
                </div>
                <div className="flex-grow-1"></div>
                <div className="clickable text-end text-decoration-none me-4">
                    <FontAwesomeIcon icon={faRefresh} className="text-color-accent"
                                     onClick={() => refreshDeals()} data-tooltip-id="refresh-tooltip"/>
                </div>
                <Tooltip
                    id={`refresh-tooltip`}
                    place="left"
                    content={t("refresh")}
                    style={tooltipStyles}
                />
                <div className="clickable text-end text-decoration-none">
                    <a data-tooltip-id="new-deal-tooltip" href={process.env.REACT_APP_NEW_DEAL_URL}
                       target="_blank">
                        <FontAwesomeIcon icon={faPlus} className="text-color-accent"/>
                    </a>
                </div>
                <Tooltip
                    id={`new-deal-tooltip`}
                    place="left"
                    content={t("newDeal")}
                    style={tooltipStyles}
                />
            </PageTitle>
            <Card>
                <SearchableList
                    loaded={loaded}
                    title={t("upcomingDeals")}
                    columnNames={dealsTableHeaders}
                    data={processDeals(data.deals)}
                />
            </Card>
        </>
    )
}

export { Deals };