import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    ActionButton,
    Card,
    EditableTitleValueLine,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    PageTitle,
    StaffSelector,
    TitleValueLine,
    tooltipStyles
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse, requestTypes } from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { routes, routeWithParams } from "helpers/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle, faPencilAlt, faSave, faTimes, faTrashAlt, faUndo } from "@fortawesome/free-solid-svg-icons";
import { dateObjectToDateString } from "helpers/date";
import { DateRangePicker } from "react-date-range";
import { Tooltip } from "react-tooltip";
import { HistoryBackButton } from "components/utils/navigation";
import { useHistoryContext } from "contexts/HistoryProvider";
import useConfirm from "hooks/useConfirmModal";

const RobotReservation = () => {
    const {t} = useTranslation();
    const {reservationId} = useParams();
    const navigate = useNavigate();
    const {updateCurrentRouteName} = useHistoryContext();

    const {confirm, ConfirmModal} = useConfirm();

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const [pointOfContact, setPointOfContact] = useState(null);
    const [editingPointOfContact, setEditingPointOfContact] = useState(false);
    const [loadedPointOfContact, setLoadedPointOfContact] = useState(true);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    //useHotkeys("esc", () => navigate(routes.deployments.path));

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.robotReservation;
        createEmptyApiResponse(requestTypes.GET, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl(reservationId)).then(apiRequestSuccess => {
            if (apiRequestSuccess) {
                setLoaded(true);
                setLoadedPointOfContact(true);
            }
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (!!data) {
            if (!!data.summary && !!data.summary.name) updateCurrentRouteName(data.summary.name);
            setPointOfContact(data.summary.pointOfContact.id);
            setDatesToData();
        }
    }, [data]);

    const setDatesToData = () => {
        setStartDate(new Date(data.dates.startDate + "T00:00:00"));
        setEndDate(new Date(data.dates.endDate + "T00:00:00"));
        return true;
    };

    const deleteRobotReservation = async () => {
        const confirmation = await confirm(t("confirmation"), t("confirmRobotReservationDeletion"), t("yes"), t("no"));
        if (!confirmation) return;
        const deleteRobotReservationEndpoint = apiEndpoints.robotReservation.constructUrl(reservationId);
        apiRequest(
            () => {
            },
            setError,
            requestTypes.DELETE,
            deleteRobotReservationEndpoint
        ).then(apiRequestSuccess => {
            if (!apiRequestSuccess) {
                alert(t("errorDeletingRobotReservation"));
                return;
            }
            navigate(routeWithParams(routes.robot.path, {id: data.summary.robot}));
        });
    }

    const updateRobotReservation = async (field, value) => {
        return await apiRequest(setData, setError, requestTypes.PUT, apiEndpoints.robotReservation.constructUrl(reservationId), {[field]: value});
    };

    useEffect(() => {
        if (startDate > endDate) setEndDate(startDate);
    }, [startDate]);
    useEffect(() => {
        if (endDate < startDate) setStartDate(endDate)
    }, [endDate]);

    const handleSelect = (ranges) => {
        if (ranges.dates) {
            const newStartDate = ranges.dates.startDate;
            const newEndDate = ranges.dates.endDate;
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        }
    };

    const updateDates = async () => {
        return await apiRequest(
            setData,
            setError,
            requestTypes.PUT,
            apiEndpoints.robotReservation.constructUrl(reservationId),
            {
                startDate: dateObjectToDateString(startDate),
                endDate: dateObjectToDateString(endDate),
            }
        );
    };

    const ranges = [
        {
            startDate: startDate,
            endDate: endDate,
            key: "dates",
            color: "#f25c07",
            autoFocus: true
        },
    ];

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <ConfirmModal/>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <HistoryBackButton className="mb-1"/>
                    {loaded ? (
                        <>
                            {/*<PageBackButton className="mb-1"
                                            baseUrl={routes.robot.path}
                                            params={{id: data.summary.robot}}>
                                {data.summary.robot}
                            </PageBackButton>*/}
                            <div className="align-items-center d-flex">
                                {data.summary.name}
                            </div>
                        </>
                    ) : (
                        <>
                            {/*<PageBackButton className="mb-1" baseUrl={null}></PageBackButton>*/}
                            <LoadingSpinner size={46}/>
                        </>
                    )}
                </div>
                <div className="flex-grow-1"></div>
                <div className="clickable text-end text-decoration-none">
                    <FontAwesomeIcon
                        data-tooltip-id="delete-robot-reservation-tooltip"
                        icon={faTrashAlt}
                        className="text-color-accent"
                        onClick={() => deleteRobotReservation()}
                    />
                </div>
                <Tooltip
                    id={`delete-robot-reservation-tooltip`}
                    place="left"
                    content={t("deleteRobotReservation")}
                    style={tooltipStyles}
                />
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("summary")}</div>
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("name")}:`}
                            value={data.summary.name}
                            className="flex-grow-1"
                            onSave={async (value) => {
                                return await updateRobotReservation(`name`, value);
                            }}
                        />
                        <EditableTitleValueLine
                            loaded={loaded}
                            title={`${t("notes")}:`}
                            value={data.summary.notes}
                            className="flex-grow-1"
                            onSave={async (value) => {
                                return await updateRobotReservation(`notes`, value);
                            }}
                        />


                        <div className="d-flex">
                            <div className="fw-bold me-1">{`${t("pointOfContact")}:`}</div>
                            {loaded ? (
                                editingPointOfContact || !loadedPointOfContact ? (
                                    <>
                                        <div className="flex-grow-1 text-color-med">
                                            <StaffSelector loaded={loadedPointOfContact}
                                                           selectedStaff={pointOfContact}
                                                           setSelectedStaff={setPointOfContact}
                                                           allowNone={true}
                                            />
                                        </div>
                                        <div className="text-color-accent text-end">
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                onClick={() => {
                                                    if (!loadedPointOfContact) return;
                                                    setLoadedPointOfContact(false);
                                                    setEditingPointOfContact(false);
                                                    updateRobotReservation("pointOfContact", pointOfContact).then(() => {
                                                        setLoadedPointOfContact(true);
                                                    })
                                                }}
                                                className="clickable pe-2"
                                            />
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                onClick={() => {
                                                    setEditingPointOfContact(false);
                                                    setPointOfContact(data.summary.pointOfContact.id);
                                                }}
                                                className="clickable"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="clickable flex-grow-1 text-color-med"
                                            onClick={() => setEditingPointOfContact(true)}
                                        >
                                            {!!data.summary.pointOfContact.id && (
                                                <LinkWithParams baseUrl={routes.operator.path}
                                                                params={{id: data.summary.pointOfContact.id}}
                                                                text={data.summary.pointOfContact.name}/>
                                            )}
                                        </div>
                                        <div className="text-color-accent text-end">
                                            <FontAwesomeIcon
                                                icon={faPencilAlt}
                                                onClick={() => setEditingPointOfContact(true)}
                                                className="clickable"
                                            />
                                        </div>
                                    </>
                                )
                            ) : (
                                <LoadingSpinner/>
                            )}
                        </div>


                        <TitleValueLine
                            loaded={true}
                            title={`${t("robot")}:`}
                            value={data.summary.robot}
                        />
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="text-center">
                            <div className="fw-bold text-size-lg my-2">{t("dates")}</div>
                            <DateRangePicker
                                onChange={handleSelect}
                                ranges={ranges}
                                shownDate={new Date()}
                                staticRanges={[]}
                                inputRanges={[]}
                                retainEndDateOnFirstSelection={true}
                            />
                            <div className="align-items-center d-flex justify-content-center mb-2 w-100">
                                <FontAwesomeIcon icon={faCircle}
                                                 className="text-color-accent ps-4"/>&nbsp;{t("reserved")}
                            </div>
                            <div className="d-flex justify-content-center mt-3 mb-1">
                                <ActionButton
                                    defaultIcon={faSave}
                                    disabled={(
                                        dateObjectToDateString(startDate) === data.dates.startDate &&
                                        dateObjectToDateString(endDate) === data.dates.endDate
                                    )}
                                    className="me-4"
                                    size={32}
                                    action={() => updateDates()}
                                    tooltipText={t("save")}
                                />
                                <ActionButton
                                    defaultIcon={faUndo}
                                    disabled={(
                                        dateObjectToDateString(startDate) === data.dates.startDate &&
                                        dateObjectToDateString(endDate) === data.dates.endDate
                                    )}
                                    size={32}
                                    action={() => setDatesToData()}
                                    tooltipText={t("undoChanges")}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}

export { RobotReservation };