import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faChevronDown,
    faChevronUp,
    faServer,
    faSignOutAlt,
    faTimes,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import { useTranslation } from "react-i18next";
import { routes } from "helpers/routes";
import { getCurrentUrl } from "helpers/url";
import Div100vh from "react-div-100vh";
import { logout } from "services/auth";

const MainNavbar = ({authInfo}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [isFleetOpen, setFleetOpen] = useState(false);
    const [isOperationsOpen, setOperationsOpen] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleFleetDropdown = () => {
        setFleetOpen(!isFleetOpen);
        setOperationsOpen(false);
    };

    const toggleOperationsDropdown = () => {
        setOperationsOpen(!isOperationsOpen);
        setFleetOpen(false);
    };

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        setFleetOpen(false);
        setOperationsOpen(false);
    };

    const currentUrl = getCurrentUrl();

    if (!authInfo || !authInfo.authenticated) return null;

    return (
        <>
            <nav className="d-none d-lg-flex flex-column main-navbar">
                <div className="d-flex flex-column main-navbar-container px-4">
                    <div className="d-flex flex-column flex-grow-1 w-100">
                        <NavLink className="navbar-brand py-4" end to={routes.home.path}>
                            <img alt={t("ruggedRobotics")} height="64px" src="/rugged-gear.png"/>
                        </NavLink>
                        <NavLink className="nav-link" end to={routes.home.path}>{t("home")}</NavLink>
                        <NavLink className="nav-link" end to={routes.schedule.path}>{t("schedule")}</NavLink>
                        <div className="clickable nav-link" onClick={toggleFleetDropdown}>
                            {t("fleet")}
                            <FontAwesomeIcon icon={isFleetOpen ? faChevronUp : faChevronDown} className="ps-2"/>
                        </div>
                        {isFleetOpen && (
                            <div className="indented">
                                <NavLink className="nav-link" end
                                         to={routes.fleet.path}>{t("home")}</NavLink>
                                <NavLink className="nav-link" to={routes.robots.path}>{t("robots")}</NavLink>
                            </div>
                        )}
                        <div className="clickable nav-link" onClick={toggleOperationsDropdown}>
                            {t("operations")}
                            <FontAwesomeIcon icon={isOperationsOpen ? faChevronUp : faChevronDown} className="ps-2"/>
                        </div>
                        {isOperationsOpen && (
                            <div className="indented">
                                <NavLink className="nav-link" end
                                         to={routes.operations.path}>{t("home")}</NavLink>
                                <NavLink className="nav-link"
                                         to={routes.operators.path}>{t("operators")}</NavLink>
                                <NavLink className="nav-link"
                                         to={routes.deployments.path}>{t("deployments")}</NavLink>
                                <NavLink className="nav-link"
                                         to={routes.sites.path}>{t("sites")}</NavLink>
                                <NavLink className="nav-link"
                                         to={routes.deals.path}>{t("deals")}</NavLink>
                            </div>
                        )}
                        <NavLink className="nav-link" end to={routes.reports.path}>{t("reports")}</NavLink>
                    </div>
                    <div className="mb-4">
                        <NavLink className="nav-link" end to={routes.systemStatus.path}>
                            <FontAwesomeIcon icon={faServer} className="pe-2"/>
                            {t("status")}
                        </NavLink>
                        <NavLink className="nav-link" end to={routes.account.path}>
                            <FontAwesomeIcon icon={faUser} className="pe-2"/>
                            {!!authInfo && !!authInfo.user ? authInfo.user : `...`}
                        </NavLink>
                        <a className="clickable nav-link"
                           onClick={() => logout(navigate)}>
                            <FontAwesomeIcon icon={faSignOutAlt} className="pe-2"/>
                            {t("logout")}
                        </a>
                    </div>
                </div>
            </nav>
            <nav className="d-lg-none flex-column mobile-navbar">
                <div className="mobile-navbar-top-bar p-2">
                    <div className="col-4">
                        <button className="navbar-open-button" type="button" onClick={toggleMenu}>
                            <FontAwesomeIcon icon={faBars} fixedWidth/>
                        </button>
                    </div>
                    <div className="col-4 d-flex justify-content-center">
                        <NavLink to={routes.home.path} className="mobile-navbar-top-bar-button">
                            <img alt={t("ruggedRobotics")} height="48px" src="/rugged-gear.png"/>
                        </NavLink>
                    </div>
                    <div className="d-flex flex-row-reverse col-4">
                        <NavLink to={routes.account.path} className="mobile-navbar-top-bar-button">
                            <FontAwesomeIcon icon={faSignOutAlt} className="me-2" fixedWidth/>
                        </NavLink>
                        <NavLink to={routes.account.path} className="mobile-navbar-top-bar-button">
                            <FontAwesomeIcon icon={faUser} className="me-2" fixedWidth/>
                        </NavLink>
                    </div>
                </div>
                <Div100vh className={`d-flex flex-column mobile-menu ${isMenuOpen ? "open" : ""}`}>
                    <div>
                        <div style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center"
                        }}>
                            <button className="navbar-close-button p-2" type="button" onClick={toggleMenu}>
                                <FontAwesomeIcon icon={faTimes} fixedWidth/>
                            </button>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <NavLink className="navbar-brand py-3" to="/" onClick={toggleMenu}>
                                <img alt={t("ruggedRobotics")} height="64px" src="/rugged-gear.png"/>
                            </NavLink>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-grow-1 overflow-y-scroll overflow-x-hidden">
                        <div className="flex-grow-1">
                            <NavLink className="nav-link" end to={routes.home.path}
                                     onClick={toggleMenu}>{t("home")}</NavLink>
                            <NavLink className="nav-link" end to={routes.schedule.path}
                                     onClick={toggleMenu}>{t("schedule")}</NavLink>
                            <div className="clickable nav-link" onClick={toggleFleetDropdown}>
                                {t("fleet")}
                                <FontAwesomeIcon icon={isFleetOpen ? faChevronUp : faChevronDown} className="ps-2"/>
                            </div>
                            {isFleetOpen && (
                                <div className="indented">
                                    <NavLink className="nav-link" end to={routes.fleet.path}
                                             onClick={toggleMenu}>{t("home")}</NavLink>
                                    <NavLink className="nav-link" to={routes.robots.path}
                                             onClick={toggleMenu}>{t("robots")}</NavLink>
                                </div>
                            )}
                            <div className="clickable nav-link" onClick={toggleOperationsDropdown}>
                                {t("operations")}
                                <FontAwesomeIcon icon={isOperationsOpen ? faChevronUp : faChevronDown}
                                                 className="ps-2"/>
                            </div>
                            {isOperationsOpen && (
                                <div className="indented">
                                    <NavLink className="nav-link" end to={routes.operations.path}
                                             onClick={toggleMenu}>{t("home")}</NavLink>
                                    <NavLink className="nav-link" to={routes.operators.path}
                                             onClick={toggleMenu}>{t("operators")}</NavLink>
                                    <NavLink className="nav-link" to={routes.deployments.path}
                                             onClick={toggleMenu}>{t("deployments")}</NavLink>
                                    <NavLink className="nav-link" to={routes.sites.path}
                                             onClick={toggleMenu}>{t("sites")}</NavLink>
                                    <NavLink className="nav-link" to={routes.deals.path}
                                             onClick={toggleMenu}>{t("deals")}</NavLink>
                                </div>
                            )}
                            <NavLink className="nav-link" end to={routes.reports.path}
                                     onClick={toggleMenu}>{t("reports")}</NavLink>
                        </div>
                        <div>
                            <NavLink className="nav-link" end to={routes.systemStatus.path}
                                     onClick={toggleMenu}>
                                <FontAwesomeIcon icon={faServer} className="pe-2"/>
                                {t("status")}
                            </NavLink>
                            <NavLink className="nav-link" end to={routes.account.path}
                                     onClick={toggleMenu}>
                                <FontAwesomeIcon icon={faUser} className="pe-2"/>
                                {!!authInfo && !!authInfo.user ? authInfo.user : ``}
                            </NavLink>
                            <a className="clickable nav-link"
                               onClick={() => logout(navigate)}>
                                <FontAwesomeIcon icon={faSignOutAlt} className="pe-2"/>
                                {t("logout")}
                            </a>
                        </div>
                    </div>
                </Div100vh>
            </nav>
        </>
    );
};

export { MainNavbar };
