import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Calendar,
    Card,
    FullPageLoading,
    LinkWithParams,
    PageTitle,
    RobotCalendarLegend,
    StaffCalendarLegend,
    styleCalendarData,
    tooltipStyles
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse, requestTypes } from "services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableCellsRowLock, faTableCellsRowUnlock } from "@fortawesome/free-solid-svg-icons";
import { useScrollSync } from "react-use-scroll-sync";
import { uniqueId } from "helpers/random";
import { Tooltip } from "react-tooltip";
import { dateObjectToDateString, getLastSundayDateObject } from "helpers/date";
import { routes } from "helpers/routes";

const Schedule = () => {
    const {t} = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const [scrollSync, setScrollSync] = useState(true)
    const siteScheduleContainerRef = React.useRef(null)
    const robotScheduleContainerRef = React.useRef(null)
    const operatorScheduleContainerRef = React.useRef(null)
    const staffScheduleContainerRef = React.useRef(null)

    const lastSunday = dateObjectToDateString(getLastSundayDateObject());

    useScrollSync([
        siteScheduleContainerRef,
        robotScheduleContainerRef,
        operatorScheduleContainerRef,
        staffScheduleContainerRef
    ], {
        horizontal: scrollSync
    })

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.schedule;
        createEmptyApiResponse(requestTypes.GET, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl()).then(apiRequestSuccess => {
            if (apiRequestSuccess) {
                setLoaded(true);
            }
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const processCalendarData = (calendarData) => {
        return calendarData.map(e => styleCalendarData(e));
    };

    const ScrollSyncToggle = () => {
        const synchronizeTooltipId = uniqueId();
        const desynchronizeTooltipId = uniqueId();
        if (scrollSync) return (
            <>
                <FontAwesomeIcon icon={faTableCellsRowLock} fixedWidth={true} className="clickable text-color-accent"
                                 data-tooltip-id={desynchronizeTooltipId}
                                 onClick={() => {
                                     setScrollSync(false)
                                 }}/>
                <Tooltip
                    id={desynchronizeTooltipId}
                    place="left"
                    content={t("desynchronizeScheduleDates")}
                    style={tooltipStyles}
                />
            </>
        );
        return (
            <>
                <FontAwesomeIcon icon={faTableCellsRowUnlock} fixedWidth={true}
                                 className="clickable text-color-accent"
                                 data-tooltip-id={synchronizeTooltipId}
                                 onClick={() => {
                                     setScrollSync(true)
                                 }}/>
                <Tooltip
                    id={synchronizeTooltipId}
                    place="left"
                    content={t("synchronizeScheduleDates")}
                    style={tooltipStyles}
                />
            </>
        )
    }

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div className="align-items-center d-flex">
                    {t("schedule")}
                    {/*<FontAwesomeIcon icon={faCircleInfo}
                                     className="clickable text-color-accent text-size-lg ps-2"
                                     data-tooltip-id="ios-info"/>
                    <Tooltip
                        id="ios-info"
                        place="bottom"
                        content={t("mayNotRenderOnIos")}
                        style={tooltipStyles}
                    />*/}
                </div>
                <div className="flex-grow-1"></div>
                <ScrollSyncToggle/>
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <Card>
                        <div className="d-flex mb-3">
                            <div className="flex-grow-1 fw-bold text-size-lg">{t("siteSchedule")}</div>
                        </div>
                        <Calendar loaded={loaded} startDate={data.startDate} endDate={data.endDate}
                                  rows={data.sites}
                                  rowLinkFunction={(id, name) => <LinkWithParams baseUrl={routes.site.path}
                                                                                 params={{id: id}} text={name}/>}
                                  events={processCalendarData(data.siteSchedule)}
                                  containerRef={siteScheduleContainerRef}
                                  initialDate={lastSunday}/>
                    </Card>
                    <Card>
                        <div className="d-flex mb-3">
                            <div className="fw-bold text-size-lg">{t("robotSchedule")}</div>
                        </div>
                        <Calendar loaded={loaded} startDate={data.startDate} endDate={data.endDate}
                                  rows={data.robots}
                                  rowLinkFunction={(id, name) => <LinkWithParams baseUrl={routes.robot.path}
                                                                                 params={{id: id}} text={name}/>}
                                  events={processCalendarData(data.robotSchedule)}
                                  containerRef={robotScheduleContainerRef}
                                  initialDate={lastSunday}/>
                        <RobotCalendarLegend/>
                    </Card>
                    <Card>
                        <div className="d-flex mb-3">
                            <div className="fw-bold text-size-lg">{t("operatorSchedule")}</div>
                        </div>
                        <Calendar loaded={loaded} startDate={data.startDate} endDate={data.endDate}
                                  rows={data.operators}
                                  rowLinkFunction={(id, name) => <LinkWithParams baseUrl={routes.operator.path}
                                                                                 params={{id: id}} text={name}/>}
                                  events={processCalendarData(data.operatorSchedule)}
                                  containerRef={operatorScheduleContainerRef}
                                  initialDate={lastSunday}/>
                        <StaffCalendarLegend/>
                    </Card>
                    <Card>
                        <div className="d-flex mb-3">
                            <div className="fw-bold text-size-lg">{t("supportStaffSchedule")}</div>
                        </div>
                        <Calendar loaded={loaded} startDate={data.startDate} endDate={data.endDate}
                                  rows={data.staff}
                                  rowLinkFunction={(id, name) => <LinkWithParams baseUrl={routes.operator.path}
                                                                                 params={{id: id}} text={name}/>}
                                  events={processCalendarData(data.staffSchedule)}
                                  containerRef={staffScheduleContainerRef}
                                  initialDate={lastSunday}/>
                        <StaffCalendarLegend/>
                    </Card>
                </div>
            </div>
        </>
    )
}

export { Schedule };