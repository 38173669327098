import React from "react";
import PropTypes from "prop-types";
import { LoadingSpinner, ProgressBar } from "components/utils/ui";
import { useTranslation } from "react-i18next";

const ProportionSummary = ({
                               loaded = true,
                               quantity = 0,
                               quantityOf = ``,
                               list = [],
                               proportion = 0.0,
                               color = `var(--accent)`,
                           }) => {
    const {t} = useTranslation();
    return (
        <div className="mt-4">
            <div className="d-flex">
                <div className="align-self-end col-6 fw-bold pe-1">
                    {loaded ? `${quantity}${t("times")} ${quantityOf}` : <LoadingSpinner size={32}/>}
                </div>
                <div className="col-6 text-color-med text-end">
                    {loaded && (list.length ? list.join(`, `) : ``)}
                </div>
            </div>
            <div>
                <ProgressBar loaded={loaded} value={proportion} color={color}/>
            </div>
        </div>
    );
};

ProportionSummary.propTypes = {
    loaded: PropTypes.bool,
    quantity: PropTypes.number,
    quantityOf: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.string),
    proportion: PropTypes.number,
    color: PropTypes.string,
};

export { ProportionSummary };
