import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faPlus, faTableCellsRowLock, faTableCellsRowUnlock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {
    Calendar,
    Card,
    colors,
    CopyButton,
    DownloadButton,
    FullPageLoading,
    LinkWithParams,
    LoadingSpinner,
    PageTitle,
    ProportionSummary,
    SearchableList,
    StaffCalendarLegend,
    stateColors,
    styleCalendarData,
    TitleValueLine,
    tooltipStyles
} from "components/utils/ui";
import { apiEndpoints, apiRequest, createEmptyApiResponse, requestTypes } from "services/api";
import { routes } from "helpers/routes";
import { convertToPrettyDate, dateObjectToDateString, getLastSundayDateObject } from "helpers/date";
import { uniqueId } from "helpers/random";
import { Tooltip } from "react-tooltip";
import { useScrollSync } from "react-use-scroll-sync";
import { CartesianGrid, Line, LineChart, Tooltip as RechartsTooltip, XAxis, YAxis } from "recharts";

const Operations = () => {
    const {t} = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    const [scrollSync, setScrollSync] = useState(true);
    const siteScheduleContainerRef = React.useRef(null);
    const operatorScheduleContainerRef = React.useRef(null);
    const staffScheduleContainerRef = React.useRef(null);
    const futureDemandChartContainerRef = React.useRef(null);

    const lastSunday = dateObjectToDateString(getLastSundayDateObject());

    useScrollSync([
        siteScheduleContainerRef,
        operatorScheduleContainerRef,
        staffScheduleContainerRef
    ], {
        horizontal: scrollSync
    })

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.operations;
        createEmptyApiResponse(requestTypes.GET, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl()).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const processFutureDemandData = (futureDemandData) => {
        return Object.keys(futureDemandData).map(date => {
            return {
                ...futureDemandData[date],
                date: convertToPrettyDate(date)
            };
        });
    }

    const FutureDemandTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={tooltipStyles}>
                    <div className="fw-bold text-size-lg">{label}</div>
                    {payload.map((entry, index) => (
                        <div key={`historical-status-chart-tooltip-item-${index}`}>
                            {entry.name === "robots" && t("robots")}
                            {entry.name === "staff" && t("staff")}
                            {entry.name === "foremen" && t("foremen")}
                            {entry.name === "targets" && t("targets")}
                            : {entry.value}
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    const operatorStatusText = () => {
        return `${data.operatorStatus.deployed.quantity}${t("times")} ${t("deployed")}: ${data.operatorStatus.deployed.list.join(`, `)}
${data.operatorStatus.inOffice.quantity}${t("times")} ${t("inOffice")}: ${data.operatorStatus.inOffice.list.join(`, `)}
${data.operatorStatus.uft.quantity}${t("times")} ${t("uft")}: ${data.operatorStatus.uft.list.join(`, `)}
${data.operatorStatus.pto.quantity}${t("times")} ${t("pto")}: ${data.operatorStatus.pto.list.join(`, `)}`;
    }

    const deploymentsTableHeaders = {
        name: t("name"),
        travelDates: t("travelDates"),
        location: t("location"),
        staff: t("staff"),
        foremanAssigned: t("foremanAssigned")
    }

    const processDeployments = (deployments) => {
        return deployments.map(deployment => {
            return {
                name: (
                    <LinkWithParams baseUrl={routes.deployment.path} params={{id: deployment.id}}
                                    text={deployment.name} key={uniqueId()}/>),
                travelDates: `${convertToPrettyDate(deployment.travelStartDate)}-${convertToPrettyDate(deployment.travelEndDate)}`,
                location: deployment.location,
                staff: (() => {
                    const staff = deployment.staff.reduce((acc, e, index) => {
                        if (index > 0) {
                            acc.push(", ");
                        }
                        acc.push(
                            <LinkWithParams baseUrl={routes.operator.path} params={{id: e.id}} text={e.name}
                                            key={uniqueId()}/>);
                        return acc;
                    }, []);
                    return (
                        <div>
                            {deployment.staffAssigned}/{deployment.staffRequired}{!!deployment.staff.length && (<>&nbsp;({staff})</>)}
                        </div>
                    );
                })(),
                foremanAssigned: deployment.staffRequired === 0 ? t("na") : (deployment.foremanAssigned ? t("yes") : t("no")),
            };
        });
    };

    const processCalendarData = (calendarData) => {
        return calendarData.map(e => styleCalendarData(e));
    };

    const ScrollSyncToggle = () => {
        const synchronizeTooltipId = uniqueId();
        const desynchronizeTooltipId = uniqueId();
        if (scrollSync) return (
            <>
                <FontAwesomeIcon icon={faTableCellsRowLock} fixedWidth={true}
                                 className="clickable text-color-accent pe-4"
                                 data-tooltip-id={desynchronizeTooltipId}
                                 onClick={() => {
                                     setScrollSync(false)
                                 }}/>
                <Tooltip
                    id={desynchronizeTooltipId}
                    place="left"
                    content={t("desynchronizeScheduleDates")}
                    style={tooltipStyles}
                />
            </>
        );
        return (
            <>
                <FontAwesomeIcon icon={faTableCellsRowUnlock} fixedWidth={true}
                                 className="clickable text-color-accent pe-4"
                                 data-tooltip-id={synchronizeTooltipId}
                                 onClick={() => {
                                     setScrollSync(true)
                                 }}/>
                <Tooltip
                    id={synchronizeTooltipId}
                    place="left"
                    content={t("synchronizeScheduleDates")}
                    style={tooltipStyles}
                />
            </>
        )
    }

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    {t("operationsOverview")}
                </div>
                <div className="flex-grow-1"></div>
                <>
                    <ScrollSyncToggle/>
                    <div className="clickable text-end text-decoration-none">
                        <LinkWithParams data-tooltip-id="new-deployment-tooltip" baseUrl={routes.newDeployment.path}>
                            <FontAwesomeIcon icon={faPlus} className="text-color-accent"/>
                        </LinkWithParams>
                    </div>
                    <Tooltip
                        id={`new-deployment-tooltip`}
                        place="left"
                        content={t("newDeployment")}
                        style={tooltipStyles}
                    />
                </>
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <Card>
                        <div className="d-flex">
                            <div className="flex-grow-1 fw-bold text-size-lg">{t("siteSchedule")}</div>
                        </div>
                        {!loaded || (data.sites && Object.keys(data.sites).length > 0) ? (
                            <div className="mt-3">
                                <Calendar loaded={loaded} startDate={data.startDate} endDate={data.endDate}
                                          rows={data.sites}
                                          rowLinkFunction={(id, name) => <LinkWithParams baseUrl={routes.site.path}
                                                                                         params={{id: id}}
                                                                                         text={name}/>}
                                          events={processCalendarData(data.siteSchedule)}
                                          containerRef={siteScheduleContainerRef}
                                          initialDate={lastSunday}/>
                            </div>

                        ) : (
                            <div className="fst-italic mt-1 text-color-med">{t("noUpcomingSiteActivity")}</div>
                        )}
                    </Card>
                    <Card>
                        <div className="d-flex">
                            <div className="fw-bold text-size-lg">{t("operatorSchedule")}</div>
                        </div>
                        <div className="mt-3">
                            <Calendar loaded={loaded} startDate={data.startDate} endDate={data.endDate}
                                      rows={data.operators}
                                      rowLinkFunction={(id, name) => <LinkWithParams baseUrl={routes.operator.path}
                                                                                     params={{id: id}} text={name}/>}
                                      events={processCalendarData(data.operatorSchedule)}
                                      containerRef={operatorScheduleContainerRef}
                                      initialDate={lastSunday}/>
                            <StaffCalendarLegend/>
                        </div>
                    </Card>
                    <Card>
                        <div className="d-flex">
                            <div className="fw-bold text-size-lg">{t("staffSchedule")}</div>
                        </div>
                        {!loaded || (data.staff && Object.keys(data.staff).length > 0) ? (
                            <div className="mt-3">
                                <Calendar loaded={loaded} startDate={data.startDate} endDate={data.endDate}
                                          rows={data.staff}
                                          rowLinkFunction={(id, name) => <LinkWithParams baseUrl={routes.operator.path}
                                                                                         params={{id: id}}
                                                                                         text={name}/>}
                                          events={processCalendarData(data.staffSchedule)}
                                          containerRef={staffScheduleContainerRef}
                                          initialDate={lastSunday}/>
                                <StaffCalendarLegend/>
                            </div>
                        ) : (
                            <div className="fst-italic mt-1 text-color-med">{t("noUpcomingStaffAssignments")}</div>
                        )}
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold mb-3 text-size-lg">{t("futureStaffDemand")}</div>
                        {loaded ? (
                            <>
                                <div className="d-flex">
                                    <div>
                                        <LineChart width={64} height={512} margin={{top: 8, left: 0, bottom: 24}}
                                                   data={processFutureDemandData(data.futureDemandData)}>
                                            <YAxis
                                                yAxisId="left"
                                                orientation="left"
                                                width={64}
                                                domain={[0, 15]}
                                                tickCount={6}
                                                padding={{bottom: 30}}
                                                tick={{fontSize: 14, fontFamily: "Public Sans", fill: "var(--dark)"}}
                                                axisLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 3}}
                                                tickLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 1}}
                                                label={{
                                                    value: t("staff"),
                                                    angle: -90,
                                                    position: "insideRight",
                                                    style: {
                                                        textAnchor: "middle",
                                                        fill: "var(--dark)",
                                                        fontSize: 16,
                                                        fontFamily: "Public Sans"
                                                    },
                                                    dx: 8
                                                }}
                                            />
                                        </LineChart>
                                    </div>
                                    <div ref={futureDemandChartContainerRef} className="flex-grow-1 overflow-x-scroll">
                                        <LineChart width={4096} height={512}
                                                   margin={{top: 8, right: 8, bottom: 24, left: -1}}
                                                   data={processFutureDemandData(data.futureDemandData)}>
                                            <YAxis
                                                yAxisId="left"
                                                orientation="left"
                                                width={64}
                                                domain={[0, 15]}
                                                tickCount={6}
                                                hide={true}
                                            />
                                            <XAxis
                                                dataKey="date"
                                                angle={-60}
                                                textAnchor="end"
                                                dx={-2}
                                                dy={2}
                                                tick={{fontSize: 14, fontFamily: "Public Sans", fill: "var(--dark)"}}
                                                axisLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 3}}
                                                tickLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 1}}
                                                interval={0}
                                            />
                                            <RechartsTooltip
                                                content={<FutureDemandTooltip/>}
                                                cursor={{stroke: "var(--dark)", strokeWidth: 1}}
                                            />
                                            <CartesianGrid strokeDasharray="3 3" horizontal={true}
                                                           horizontalValues={[3, 6, 9, 12, 15]} vertical={false}/>
                                            <Line
                                                dataKey="foremen"
                                                yAxisId="left"
                                                type="linear"
                                                stroke={colors.med}
                                                strokeWidth={3}
                                                dot={false}
                                                activeDot={{
                                                    r: 4,
                                                    fill: colors.med,
                                                    strokeWidth: 2,
                                                    stroke: "var(--white)",
                                                    opacity: 1,
                                                    strokeOpacity: 1
                                                }}
                                            />
                                            <Line
                                                dataKey="staff"
                                                yAxisId="left"
                                                type="linear"
                                                stroke={colors.accent}
                                                strokeWidth={3}
                                                dot={false}
                                                activeDot={{
                                                    r: 4,
                                                    fill: colors.accent,
                                                    strokeWidth: 2,
                                                    stroke: "var(--white)",
                                                    opacity: 1,
                                                    strokeOpacity: 1
                                                }}
                                            />
                                        </LineChart>
                                    </div>
                                </div>
                                <div className="align-items-center d-flex flex-wrap mt-1">
                                    <div className="align-items-center d-flex flex-nowrap">
                                        <FontAwesomeIcon icon={faCircle}
                                                         style={{color: colors.accent}}/>&nbsp;{t("staff")}
                                        <div className="me-4"></div>
                                        <FontAwesomeIcon icon={faCircle}
                                                         style={{color: colors.med}}/>&nbsp;{t("foremen")}
                                        <div className="me-4"></div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <LoadingSpinner size={48}></LoadingSpinner>
                        )}
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold mb-3 text-size-lg">{t("futureEquipmentDemand")}</div>
                        {loaded ? (
                            <>
                                <div className="d-flex">
                                    <div>
                                        <LineChart width={64} height={512} margin={{top: 8, right: 0, bottom: 24}}
                                                   data={processFutureDemandData(data.futureDemandData)}>
                                            <YAxis
                                                yAxisId="left"
                                                width={64}
                                                domain={[0, 20]}
                                                tickCount={6}
                                                padding={{bottom: 30}}
                                                tick={{fontSize: 14, fontFamily: "Public Sans", fill: "var(--dark)"}}
                                                axisLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 3}}
                                                tickLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 1}}
                                                label={{
                                                    value: t("robots"),
                                                    angle: -90,
                                                    position: "insideLeft",
                                                    style: {
                                                        textAnchor: "middle",
                                                        fill: "var(--dark)",
                                                        fontSize: 16,
                                                        fontFamily: "Public Sans"
                                                    },
                                                    dx: 8
                                                }}
                                            />
                                        </LineChart>
                                    </div>
                                    <div ref={futureDemandChartContainerRef} className="flex-grow-1 overflow-x-scroll">
                                        <LineChart width={4096} height={512}
                                                   margin={{top: 8, right: 8, bottom: 24, left: -1}}
                                                   data={processFutureDemandData(data.futureDemandData)}>
                                            <YAxis
                                                yAxisId="left"
                                                orientation="left"
                                                width={64}
                                                domain={[0, 20]}
                                                tickCount={6}
                                                hide={true}
                                            />
                                            <YAxis
                                                yAxisId="right"
                                                orientation="right"
                                                width={64}
                                                domain={[0, 100]}
                                                tickCount={6}
                                                hide={true}
                                            />
                                            <XAxis
                                                dataKey="date"
                                                angle={-60}
                                                textAnchor="end"
                                                dx={-2}
                                                dy={2}
                                                tick={{fontSize: 14, fontFamily: "Public Sans", fill: "var(--dark)"}}
                                                axisLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 3}}
                                                tickLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 1}}
                                                interval={0}
                                            />
                                            <RechartsTooltip
                                                content={<FutureDemandTooltip/>}
                                                cursor={{stroke: "var(--dark)", strokeWidth: 1}}
                                            />
                                            <CartesianGrid strokeDasharray="3 3" horizontal={true}
                                                           horizontalValues={[4, 8, 12, 16, 20]} vertical={false}/>
                                            <Line
                                                dataKey="targets"
                                                yAxisId="right"
                                                type="linear"
                                                stroke={colors.med}
                                                strokeWidth={3}
                                                dot={false}
                                                activeDot={{
                                                    r: 4,
                                                    fill: colors.med,
                                                    strokeWidth: 2,
                                                    stroke: "var(--white)",
                                                    opacity: 1,
                                                    strokeOpacity: 1
                                                }}
                                            />
                                            <Line
                                                dataKey="robots"
                                                yAxisId="left"
                                                type="linear"
                                                stroke={colors.accent}
                                                strokeWidth={3}
                                                dot={false}
                                                activeDot={{
                                                    r: 4,
                                                    fill: colors.accent,
                                                    strokeWidth: 2,
                                                    stroke: "var(--white)",
                                                    opacity: 1,
                                                    strokeOpacity: 1
                                                }}
                                            />
                                        </LineChart>
                                    </div>
                                    <div>
                                        <LineChart width={64} height={512} margin={{top: 8, left: 0, bottom: 24}}
                                                   data={processFutureDemandData(data.futureDemandData)}>
                                            <YAxis
                                                yAxisId="right"
                                                orientation="right"
                                                width={64}
                                                domain={[0, 100]}
                                                tickCount={6}
                                                padding={{bottom: 30}}
                                                tick={{fontSize: 14, fontFamily: "Public Sans", fill: "var(--dark)"}}
                                                axisLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 3}}
                                                tickLine={{stroke: "var(--dark)", strokeOpacity: 1, strokeWidth: 1}}
                                                label={{
                                                    value: t("targets"),
                                                    angle: 90,
                                                    position: "insideRight",
                                                    style: {
                                                        textAnchor: "middle",
                                                        fill: "var(--dark)",
                                                        fontSize: 16,
                                                        fontFamily: "Public Sans"
                                                    },
                                                    dx: -8
                                                }}
                                            />
                                        </LineChart>
                                    </div>
                                </div>
                                <div className="align-items-center d-flex flex-wrap mt-1">
                                    <div className="align-items-center d-flex flex-nowrap">
                                        <FontAwesomeIcon icon={faCircle}
                                                         style={{color: colors.accent}}/>&nbsp;{t("robots")}
                                        <div className="me-4"></div>
                                        <FontAwesomeIcon icon={faCircle}
                                                         style={{color: colors.med}}/>&nbsp;{t("targets")}
                                        <div className="me-4"></div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <LoadingSpinner size={48}></LoadingSpinner>
                        )}
                    </Card>
                </div>
            </div>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("operatorStatus")}</div>
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.operatorStatus.deployed.quantity}
                            quantityOf={t("deployed")}
                            list={data.operatorStatus.deployed.list}
                            proportion={data.operatorStatus.deployed.proportion}
                            color={stateColors.deployed}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.operatorStatus.inOffice.quantity}
                            quantityOf={t("inOffice")}
                            list={data.operatorStatus.inOffice.list}
                            proportion={data.operatorStatus.inOffice.proportion}
                            color={stateColors.inOffice}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.operatorStatus.uft.quantity}
                            quantityOf={t("uft")}
                            list={data.operatorStatus.uft.list}
                            proportion={data.operatorStatus.uft.proportion}
                            color={stateColors.uft}
                        />
                        <ProportionSummary
                            loaded={loaded}
                            quantity={data.operatorStatus.pto.quantity}
                            quantityOf={t("pto")}
                            list={data.operatorStatus.pto.list}
                            proportion={data.operatorStatus.pto.proportion}
                            color={stateColors.pto}
                            marginBottom={false}
                        />
                        {!loaded ? (
                            <div className="mt-4">
                                <LoadingSpinner size={32}/>
                            </div>
                        ) : (
                            <div className="d-flex mt-4">
                                <CopyButton text={operatorStatusText()} size={24} className="pe-1"/>
                                <DownloadButton text={operatorStatusText()} size={24}/>
                            </div>
                        )}
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("nextWeek")}</div>
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("deployments")}:`}
                            value={data.nextWeek.deploymentsQuantity}
                            className="mt-2"
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("robots")}:`}
                            value={data.nextWeek.robotsQuantity}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("staff")}:`}
                            value={data.nextWeek.staffQuantity}
                        />
                        <SearchableList
                            loaded={loaded}
                            title={t("deployments")}
                            columnNames={deploymentsTableHeaders}
                            data={processDeployments(data.nextWeek.deploymentsList)}
                            className={"mt-4"}
                        />
                    </Card>
                </div>
                <div className="col-12 col-lg-6">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("thisWeek")}</div>
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("deployments")}:`}
                            value={data.thisWeek.deploymentsQuantity}
                            className="mt-2"
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("robots")}:`}
                            value={data.thisWeek.robotsQuantity}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("staff")}:`}
                            value={data.thisWeek.staffQuantity}
                        />
                        <SearchableList
                            loaded={loaded}
                            title={t("deployments")}
                            columnNames={deploymentsTableHeaders}
                            data={processDeployments(data.thisWeek.deploymentsList)}
                            className="mt-4"
                        />
                    </Card>
                    <Card>
                        <div className="fw-bold text-size-lg">{t("twoWeeks")}</div>
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("deployments")}:`}
                            value={data.twoWeeks.deploymentsQuantity}
                            className="mt-2"
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("robots")}:`}
                            value={data.twoWeeks.robotsQuantity}
                        />
                        <TitleValueLine
                            loaded={loaded}
                            title={`${t("staff")}:`}
                            value={data.twoWeeks.staffQuantity}
                        />
                        <SearchableList
                            loaded={loaded}
                            title={t("deployments")}
                            columnNames={deploymentsTableHeaders}
                            data={processDeployments(data.twoWeeks.deploymentsList)}
                            className={"mt-4"}
                        />
                    </Card>
                </div>
            </div>
        </>
    )
}

export { Operations };