import React, { createContext, useContext, useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { getRouteNameByPath, routes } from "helpers/routes";

const MAX_STACK_SIZE = 256;

const routesExcludedFromHistory = [
    routes.logoutSuccess,
    routes.loginCallback,
    routes.newRobot,
    routes.newRobotReservation,
    routes.updateRobotMaintenanceStatus,
    routes.updateRobotCalibrationStatus,
    routes.newDeployment,
    routes.linkSiteToDeployment,
    routes.assignment,
    routes.newSite,
    routes.grantSiteFilestoreAccess,
    routes.linkDealToSite,
    routes.linkDeploymentToSite,
    routes.newFilestore,
    routes.linkSiteToDeal,
    routes.cacheNotFound,
];

const HistoryContext = createContext();

export const HistoryProvider = ({children}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const navigationType = useNavigationType(); // Detects the navigation action type
    const [historyStack, setHistoryStack] = useState([]);

    const noHistoryPaths = Object.values(routes)
        .filter(route => routesExcludedFromHistory.includes(route))
        .map(route => route.path);

    const isExcludedPath = (path) => {
        return noHistoryPaths.some(noHistoryPath => !!matchPath({path: noHistoryPath, end: true}, path));
    };

    useEffect(() => {
        const currentPath = location.pathname;
        const routeName = getRouteNameByPath(currentPath);

        setHistoryStack((prevStack) => {
            if (navigationType === "POP") {
                const lastValidRoute = getSecondLastRouteInStack();
                if (lastValidRoute && lastValidRoute.path === currentPath) return prevStack.slice(0, prevStack.length - 1); //pop stack only if the new path is the second most recent in the stack (i.e. we have gone back)
                return prevStack;
            }
            if (isExcludedPath(currentPath)) return prevStack; //skip excluded paths
            if (prevStack.length && prevStack[prevStack.length - 1].path === currentPath) return prevStack; //avoid duplicates
            const newStack = [...prevStack, {path: currentPath, name: routeName}];
            if (newStack.length > MAX_STACK_SIZE) return newStack.slice(newStack.length - MAX_STACK_SIZE);
            return newStack;
        });
    }, [navigationType, location.pathname]);

    const getLastValidRoute = () => {
        const validStack = historyStack.filter(
            (route) => !isExcludedPath(route.path) && route.path !== location.pathname
        );
        return validStack.length ? validStack[validStack.length - 1] : null;
    };

    const getSecondLastRouteInStack = () => {
        const validStack = historyStack.filter(
            (route) => !isExcludedPath(route.path)
        );
        return validStack.length ? validStack[validStack.length - 2] : null;
    }

    const popLastValidRoute = () => {
        setHistoryStack((prevStack) => {
            const validStack = prevStack.filter(
                (route) => !isExcludedPath(route.path) && route.path !== location.pathname
            );
            return validStack.length ? prevStack.slice(0, prevStack.length - 1) : prevStack;
        });
    };

    const updateCurrentRouteName = (newName) => {
        setHistoryStack((prevStack) =>
            prevStack.map(route =>
                route.path === location.pathname ? {...route, name: newName} : route
            )
        );
    };

    return (
        <HistoryContext.Provider value={{historyStack, getLastValidRoute, popLastValidRoute, updateCurrentRouteName}}>
            {children}
        </HistoryContext.Provider>
    );
};

export const useHistoryContext = () => useContext(HistoryContext);
