import { isValidElement } from "react";

export const extractTextContent = (node) => {
    if (typeof node === "string" || typeof node === "number") {
        return node.toString();
    }
    if (Array.isArray(node)) {
        return node.map(extractTextContent).join("");
    }
    if (isValidElement(node)) {
        if (node.props.children) return extractTextContent(node.props.children);
        if (node.props.text) return extractTextContent(node.props.text);
        return node.props.to || node.props.href || "";
    }
    return "";
};