import React from "react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routes } from "helpers/routes";
import { useHistoryContext } from "contexts/HistoryProvider";

const HistoryBackButton = ({className = "", fallbackPath = routes.home.path, children}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {getLastValidRoute, popLastValidRoute} = useHistoryContext();

    const lastValidRoute = getLastValidRoute();

    const handleBack = () => {
        if (lastValidRoute) {
            popLastValidRoute();
            navigate(lastValidRoute.path);
        } else {
            navigate(fallbackPath);
        }
    };

    //if (!lastValidRoute && fallbackPath === routes.home.path) return null;

    return (
        <div
            onClick={handleBack}
            className={`align-items-center d-flex fw-normal text-color-accent text-decoration-none text-size-lg ${className}`}
            style={{cursor: "pointer"}}
        >
            <FontAwesomeIcon icon={faChevronLeft}/>
            <div className="ps-1">
                {children || lastValidRoute?.name || t("home")}
            </div>
        </div>
    );
};

export { HistoryBackButton };
