import axios from "axios";
import PropTypes from "prop-types";
import { t } from "i18next";
import Ajv from "ajv/dist/2020";
import { getToken, redirectToLogin } from "services/auth";

const apiEndpoints = {
    home: {
        constructUrl: () => `/portal/v1/home`,
        schemaPath: `/portal/v1/home`
    },
    schedule: {
        constructUrl: () => `/portal/v1/schedule`,
        schemaPath: `/portal/v1/schedule`
    },
    fleet: {
        constructUrl: () => `/portal/v1/fleet`,
        schemaPath: `/portal/v1/fleet`
    },
    robots: {
        constructUrl: () => `/portal/v1/robots`,
        schemaPath: `/portal/v1/robots`
    },
    robot: {
        constructUrl: (robotId) => `/portal/v1/robots/${robotId}`,
        schemaPath: `/portal/v1/robots/_`
    },
    robotNetwork: {
        constructUrl: (robotId) => `/portal/v1/robots/${robotId}/network`,
        schemaPath: `/portal/v1/robots/_/network`
    },
    staffList: {
        constructUrl: () => `/portal/v1/staff-list`,
        schemaPath: `/portal/v1/staff-list`
    },
    mobileProviderList: {
        constructUrl: () => `/portal/v1/options/mobile-provider`,
        schemaPath: `/portal/v1/options/mobile-provider`
    },
    robotGenerationList: {
        constructUrl: () => `/portal/v1/options/robot-generation`,
        schemaPath: `/portal/v1/options/robot-generation`
    },
    newRobotReservation: {
        constructUrl: () => `/portal/v1/robot-reservations`,
        schemaPath: null
    },
    robotReservation: {
        constructUrl: (robotReservationId) => `/portal/v1/robot-reservations/${robotReservationId}`,
        schemaPath: `/portal/v1/robot-reservations/_`
    },
    robotActivity: {
        constructUrl: (robotId) => `/portal/v1/robots/${robotId}/activity`,
        schemaPath: `/portal/v1/robots/_/activity`
    },
    robotMaintenanceStatusList: {
        constructUrl: () => `/portal/v1/options/robot-maintenance-status`,
        schemaPath: `/portal/v1/options/robot-maintenance-status`
    },
    robotCalibrationStatusList: {
        constructUrl: () => `/portal/v1/options/robot-calibration-status`,
        schemaPath: `/portal/v1/options/robot-calibration-status`
    },
    robotMaintenanceHistory: {
        constructUrl: (robotId) => `/portal/v1/robots/${robotId}/maintenance`,
        schemaPath: `/portal/v1/robots/_/maintenance`
    },
    robotCalibrationHistory: {
        constructUrl: (robotId) => `/portal/v1/robots/${robotId}/calibration`,
        schemaPath: `/portal/v1/robots/_/calibration`
    },
    updateRobotMaintenanceStatus: {
        constructUrl: () => `/portal/v1/robots/maintenance`,
        schemaPath: null
    },
    updateRobotCalibrationStatus: {
        constructUrl: () => `/portal/v1/robots/calibration`,
        schemaPath: null
    },
    operations: {
        constructUrl: () => `/portal/v1/operations`,
        schemaPath: `/portal/v1/operations`
    },
    operators: {
        constructUrl: () => `/portal/v1/operators`,
        schemaPath: `/portal/v1/operators`
    },
    allStaff: {
        constructUrl: () => `/portal/v1/all-staff`,
        schemaPath: `/portal/v1/all-staff`
    },
    operator: {
        constructUrl: (operatorId) => `/portal/v1/operators/${operatorId}`,
        schemaPath: `/portal/v1/operators/_`
    },
    deployments: {
        constructUrl: () => `/portal/v1/deployments`,
        schemaPath: `/portal/v1/deployments`
    },
    allDeployments: {
        constructUrl: () => `/portal/v1/all-deployments`,
        schemaPath: `/portal/v1/all-deployments`
    },
    deployment: {
        constructUrl: (deploymentId) => `/portal/v1/deployments/${deploymentId}`,
        schemaPath: `/portal/v1/deployments/_`
    },
    deploymentSiteOptions: {
        constructUrl: (deploymentId) => `/portal/v1/deployments/${deploymentId}/site-options`,
        schemaPath: `/portal/v1/deployments/_/site-options`,
    },
    linkDeploymentAndSite: {
        constructUrl: (deploymentId, siteId) => `/portal/v1/deployments/${deploymentId}/site/${siteId}`,
        schemaPath: `/portal/v1/deployments/_/site/_`,
    },
    deploymentConfidenceLevelList: {
        constructUrl: () => `/portal/v1/options/deployment-confidence-level`,
        schemaPath: `/portal/v1/options/deployment-confidence-level`
    },
    deploymentAssignmentOptions: {
        constructUrl: (deploymentId) => `/portal/v1/deployments/${deploymentId}/assignment-options`,
        schemaPath: `/portal/v1/deployments/_/assignment-options`,
    },
    assignment: {
        constructUrl: (assignmentId) => `/portal/v1/assignments/${assignmentId}`,
        schemaPath: `/portal/v1/assignments/_`,
    },
    newAssignment: {
        constructUrl: () => `/portal/v1/assignments`,
        schemaPath: null
    },
    sites: {
        constructUrl: () => `/portal/v1/sites`,
        schemaPath: `/portal/v1/sites`
    },
    site: {
        constructUrl: (siteId) => `/portal/v1/sites/${siteId}`,
        schemaPath: `/portal/v1/sites/_`,
    },
    siteFilestoreAccessOptions: {
        constructUrl: (siteId) => `/portal/v1/sites/${siteId}/filestore-access-options`,
        schemaPath: `/portal/v1/sites/_/filestore-access-options`
    },
    siteFilestoreAccess: {
        constructUrl: (siteId, userId) => `/portal/v1/sites/${siteId}/filestore-access/${userId}`,
        schemaPath: `/portal/v1/sites/_/filestore-access/_`
    },
    siteDealOptions: {
        constructUrl: (siteId) => `/portal/v1/sites/${siteId}/deal-options`,
        schemaPath: `/portal/v1/sites/_/deal-options`,
    },
    siteDeploymentOptions: {
        constructUrl: (siteId) => `/portal/v1/sites/${siteId}/deployment-options`,
        schemaPath: `/portal/v1/sites/_/deployment-options`,
    },
    newFilestoreOptions: {
        constructUrl: (siteId) => `/portal/v1/sites/${siteId}/filestore-options`,
        schemaPath: `/portal/v1/sites/_/filestore-options`,
    },
    newFilestore: {
        constructUrl: () => `/portal/v1/filestores`,
        schemaPath: `/portal/v1/filestores`,
    },
    filestoreStorage: {
        constructUrl: (filestoreId, path) => `/portal/v1/filestores/${filestoreId}/storage/${path}`,
        schemaPath: null,//`/portal/v1/filestores/_/storage/_`
    },
    filestoreDownload: {
        constructUrl: (filestoreId, path) => `/portal/v1/filestores/${filestoreId}/download/${path}`,
        schemaPath: `/portal/v1/filestores/_/download/_`
    },
    deals: {
        constructUrl: () => `/portal/v1/deals`,
        schemaPath: `/portal/v1/deals`,
    },
    dealSiteOptions: {
        constructUrl: (dealId) => `/portal/v1/deals/${dealId}/site-options`,
        schemaPath: `/portal/v1/deals/_/site-options`,
    },
    linkDealAndSite: {
        constructUrl: (dealId, siteId) => `/portal/v1/deals/${dealId}/site/${siteId}`,
        schemaPath: `/portal/v1/deals/_/site/_`,
    },
    refreshDeals: {
        constructUrl: () => `/portal/v1/refresh-deals`,
        schemaPath: `/portal/v1/refresh-deals`,
    },
    deal: {
        constructUrl: (dealId) => `/portal/v1/deals/${dealId}`,
        schemaPath: `/portal/v1/deals/_`,
    },
    generateUtilizationReport: {
        constructUrl: () => `/portal/v1/generate-report/utilization`,
        schemaPath: null,
    }
};

const apiRequest = async (setResponse, setError, requestMethod, endpoint, payload = {}) => {
    try {
        const requestUrl = `${process.env.REACT_APP_API_URL}${endpoint}`;
        let response;
        const axiosConfig = {
            headers: {
                "Authorization": `Bearer ${getToken()}`
            },
            withCredentials: true,
        };
        switch (requestMethod.toLowerCase()) {
            case `get`:
                const queryParams = new URLSearchParams(payload).toString();
                response = await axios.get(`${requestUrl}${queryParams ? `?${queryParams}` : ``}`, axiosConfig);
                break;
            case `post`:
                response = await axios.post(requestUrl, payload, axiosConfig);
                break;
            case `put`:
                response = await axios.put(requestUrl, payload, axiosConfig);
                break;
            case `delete`:
                response = await axios.delete(requestUrl, {...axiosConfig, data: payload});
                break;
            default:
                console.error(`Invalid request method: ${requestMethod}`);
        }
        if (response.data && response.data.code >= 200 && response.data.code < 300) {
            const validApiResponse = await validateApiResponse(requestMethod, endpoint, response.data.data);
            if (validApiResponse) {
                setResponse(response.data.data);
                return true;
            } else {
                setError(t("invalidApiResponse"));
                return false;
            }
        } else {
            setError(`${t("errorFetchingData")}${response.status ? ` (${response.status})` : ``}`);
            return false;
        }
    } catch (error) {
        if (error.status === 401) {
            redirectToLogin();
        } else if (error.status === 403) {
            setError(t("unauthorized"));
            return false;
        } else {
            setError(`${t("errorFetchingData")}${error.status ? ` (${error.status})` : ``}`);
            return false;
        }
    }
};

apiRequest.propTypes = {
    setResponse: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    endpoint: PropTypes.string.isRequired,
    requestType: PropTypes.oneOf(["get", "post", "put", "delete"]).isRequired,
    payload: PropTypes.object,
};

const apiFileRequest = async (setError, requestMethod, endpoint = ``, payload = {}) => {
    try {
        const requestUrl = `${process.env.REACT_APP_API_URL}${endpoint}`;
        let response;
        const axiosConfig = {
            headers: {
                "Authorization": `Bearer ${getToken()}`
            },
            withCredentials: true,
            responseType: `blob`,
            maxRedirects: 1
        };
        switch (requestMethod.toLowerCase()) {
            case `get`:
                const queryParams = new URLSearchParams(payload).toString();
                response = await axios.get(`${requestUrl}${queryParams ? `?${queryParams}` : ``}`, axiosConfig);
                break;
            case `post`:
                response = await axios.post(requestUrl, payload, axiosConfig);
                break;
            case `put`:
                response = await axios.put(requestUrl, payload, axiosConfig);
                break;
            case `delete`:
                response = await axios.delete(requestUrl, {...axiosConfig, data: payload});
                break;
            default:
                console.error(`Invalid request method: ${requestMethod}`);
                return false;
        }
        const contentDisposition = response.headers["content-disposition"] || response.headers["Content-Disposition"];
        if (contentDisposition && contentDisposition.includes("attachment")) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement(`a`);
            link.href = url;
            const filename = contentDisposition.split("filename=")[1].split(";")[0].replace(/"/g, ``);
            link.setAttribute(`download`, filename);
            link.style.position = "absolute";
            link.style.left = "-9999px";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return true;
        } else {
            setError(`${t("errorFetchingFile")}${response.status ? ` (${response.status})` : ``}`);
            return false;
        }
    } catch (error) {
        if (error.status === 401) {
            redirectToLogin();
        } else if (error.status === 403) {
            setError(t("unauthorized"));
            return false;
        } else {
            setError(`${t("errorFetchingData")}${error.status ? ` (${error.status})` : ``}`);
            return false;
        }
    }
};

apiRequest.propTypes = {
    setError: PropTypes.func.isRequired,
    endpoint: PropTypes.string.isRequired,
    requestType: PropTypes.oneOf(["get", "post", "put", "delete"]).isRequired,
    payload: PropTypes.object,
};

const validateApiResponse = async (requestMethod, schemaPath, apiResponse) => {
    const ajv = new Ajv({strict: false});
    try {
        const schema = await loadSchema(requestMethod, schemaPath);
        const validate = ajv.compile(schema);
        const valid = validate(apiResponse);
        if (!valid) console.error(validate.errors);
        return valid;
    } catch (error) {
        console.error(`Proceeding without validation due to error loading schema: ${error}`);
        return true;
    }
};

validateApiResponse.propTypes = {
    schemaPath: PropTypes.string.isRequired,
    apiResponse: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

const createEmptyApiResponse = async (requestMethod, schemaPath) => {
    try {
        const schema = await loadSchema(requestMethod, schemaPath);
        return createEmptyObject(schema);
    } catch (error) {
        console.error(`Error creating empty API response: ${error}`);
        return null;
    }
};

createEmptyApiResponse.propTypes = {
    schemaPath: PropTypes.string.isRequired,
};

const loadSchema = async (requestMethod, schemaPath) => {
    const schemasBaseUrl = `${process.env.PUBLIC_URL}/schemas/response`;
    if (schemaPath.endsWith(`/`)) schemaPath = `${schemaPath}index`;
    const schemaUrl = `${schemasBaseUrl}/${requestMethod}${schemaPath}.json`;
    const response = await fetch(schemaUrl);
    if (!response.ok) throw new Error(`Failed to fetch schema from ${schemaUrl}`);
    return await response.json();
};

loadSchema.propTypes = {
    schemaPath: PropTypes.string.isRequired,
};

const createEmptyObject = (schema, definitions = schema.$defs || schema.definitions) => {
    if (schema.$ref) {
        const refSchema = schema.$ref.replace(`#/definitions/`, ``).replace(`#/$defs/`, ``);
        return createEmptyObject(definitions[refSchema], definitions);
    }
    if (schema.type === `object`) {
        const obj = {};
        if (schema.properties) {
            for (const key in schema.properties) {
                obj[key] = createEmptyObject(schema.properties[key], definitions);
            }
        }
        return obj;
    }
    if (schema.type === `array`) return [];
    if (schema.type === `string`) return ``;
    if (schema.type === `number`) return 0;
    if (schema.type === "integer") return 0;
    return null;
};

createEmptyObject.propTypes = {
    schema: PropTypes.object.isRequired,
    definitions: PropTypes.object,
};

const requestTypes = {
    DELETE: "delete",
    GET: "get",
    POST: "post",
    PUT: "put",
}

export { apiRequest, apiFileRequest, createEmptyApiResponse, apiEndpoints, requestTypes };
