import axios from "axios";

export const checkSystemStatus = async (url, setStatus) => {
    try {
        const response = await axios.get(url);
        if (response.status === 200 || response.status === 304) {
            setStatus(true);
        } else {
            setStatus(false);
        }
    } catch (error) {
        setStatus(false);
    }
};