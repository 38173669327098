import { authGroups } from "services/auth";
import { matchPath } from "react-router-dom";

export const routes = {
    logoutSuccess: {
        path: `/logout-success`,
        component: `LogoutSuccess`
    },
    loginCallback: {
        path: `/login-callback`,
        component: `LoginCallback`
    },
    account: {
        name: "Account",
        path: `/account`,
        component: `Account`,
        authGroups: [authGroups.users]
    },
    systemStatus: {
        name: "System Status",
        path: `/system-status`,
        component: `SystemStatus`,
        authGroups: [authGroups.users]
    },
    home: {
        name: "Home",
        path: `/`,
        component: `Home`,
        authGroups: [authGroups.users]
    },
    schedule: {
        name: "Schedule",
        path: `/schedule`,
        component: `Schedule`,
        authGroups: [authGroups.users]
    },
    fleet: {
        name: "Fleet Home",
        path: `/fleet/`,
        component: `Fleet`,
        authGroups: [authGroups.users]
    },
    robots: {
        name: "Robots",
        path: `/fleet/robots`,
        component: `Robots`,
        authGroups: [authGroups.users]
    },
    robot: {
        name: "Robot",
        path: `/fleet/robots/:id`,
        component: `Robot`,
        authGroups: [authGroups.users]
    },
    newRobot: {
        name: "New Robot",
        path: `/fleet/new-robot`,
        component: `NewRobot`,
        authGroups: [authGroups.fleetAdmins]
    },
    newRobotReservation: {
        name: "New Robot Reservation",
        path: `/fleet/robots/:robotId/new-reservation`,
        component: `NewRobotReservation`,
        authGroups: [authGroups.fleetAdmins]
    },
    robotReservation: {
        name: "Robot Reservation",
        path: `/fleet/reservations/:reservationId`,
        component: `RobotReservation`,
        authGroups: [authGroups.users]
    },
    robotActivity: {
        name: "Robot Activity",
        path: `/fleet/robots/:id/activity`,
        component: `RobotActivity`,
        authGroups: [authGroups.users]
    },
    robotMaintenanceHistory: {
        name: "Robot Maintenance History",
        path: `/fleet/robots/:id/maintenance/history`,
        component: `RobotMaintenanceHistory`,
        authGroups: [authGroups.users]
    },
    robotCalibrationHistory: {
        name: "Robot Calibration History",
        path: `/fleet/robots/:id/calibration/history`,
        component: `RobotCalibrationHistory`,
        authGroups: [authGroups.users]
    },
    updateRobotMaintenanceStatus: {
        name: "Update Robot Maintenance Status",
        path: `/fleet/robots/:id/maintenance/update`,
        component: `UpdateRobotMaintenanceStatus`,
        authGroups: [authGroups.fleetAdmins]
    },
    updateRobotCalibrationStatus: {
        name: "Update Robot Calibration Status",
        path: `/fleet/robots/:id/calibration/update`,
        component: `UpdateRobotCalibrationStatus`,
        authGroups: [authGroups.fleetAdmins]
    },
    operations: {
        name: "Operations Home",
        path: `/operations/`,
        component: `Operations`,
        authGroups: [authGroups.users]
    },
    operators: {
        name: "Operators",
        path: `/operations/operators`,
        component: `Operators`,
        authGroups: [authGroups.users]
    },
    operator: {
        name: "Operator",
        path: `/operations/operators/:id`,
        component: `Operator`,
        authGroups: [authGroups.users]
    },
    deployments: {
        name: "Deployments",
        path: `/operations/deployments`,
        component: `Deployments`,
        authGroups: [authGroups.users]
    },
    deployment: {
        name: "Deployment",
        path: `/operations/deployments/:id`,
        component: `Deployment`,
        authGroups: [authGroups.users]
    },
    newDeployment: {
        name: "New Deployment",
        path: `/operations/new-deployment`,
        component: `NewDeployment`,
        authGroups: [authGroups.operationsAdmins]
    },
    linkSiteToDeployment: {
        name: "Link Site to Deployment",
        path: `/operations/deployments/:deploymentId/link-site`,
        component: `LinkDeploymentAndSite`,
        authGroups: [authGroups.operationsAdmins]
    },
    assignment: {
        name: "Assignment",
        path: `/operations/assignment/:page/:id/:type?`,
        component: `Assignment`,
        authGroups: [authGroups.fleetAdmins, authGroups.operationsAdmins]
    },
    sites: {
        name: "Sites",
        path: `/operations/sites`,
        component: `Sites`,
        authGroups: [authGroups.users]
    },
    site: {
        name: "Site",
        path: `/operations/sites/:id`,
        component: `Site`,
        authGroups: [authGroups.users]
    },
    newSite: {
        name: "New Site",
        path: `/operations/new-site`,
        component: `NewSite`,
        authGroups: [authGroups.operationsAdmins]
    },
    grantSiteFilestoreAccess: {
        name: "Grant Site Filestore Access",
        path: "/sites/:siteId/grant-filestore-access",
        component: `GrantSiteFilestoreAccess`,
        authGroups: [authGroups.operationsAdmins]
    },
    linkDealToSite: {
        name: "Link Deal to Site",
        path: `/operations/sites/:siteId/link-deal`,
        component: `LinkDealAndSite`,
        authGroups: [authGroups.operationsAdmins]
    },
    linkDeploymentToSite: {
        name: "Link Deployment to Site",
        path: `/operations/sites/:siteId/link-deployment`,
        component: `LinkDeploymentAndSite`,
        authGroups: [authGroups.operationsAdmins]
    },
    newFilestore: {
        name: "New Filestore",
        path: `/operations/sites/:siteId/new-filestore`,
        component: `NewFilestore`,
        authGroups: [authGroups.users]
    },
    filestore: {
        name: "Filestore",
        path: `/operations/filestores/:id/:path?`,
        component: `Filestore`,
        authGroups: [authGroups.users]
    },
    deals: {
        name: "Deals",
        path: `/operations/deals`,
        component: `Deals`,
        authGroups: [authGroups.users]
    },
    deal: {
        name: "Deal",
        path: `/operations/deals/:id`,
        component: `Deal`,
        authGroups: [authGroups.users]
    },
    linkSiteToDeal: {
        name: "Link Site to Deal",
        path: `/operations/deals/:dealId/link-site`,
        component: `LinkDealAndSite`,
        authGroups: [authGroups.operationsAdmins]
    },
    reports: {
        name: "Reports",
        path: `/reports/`,
        component: `Reports`,
        authGroups: [authGroups.fleetAdmins, authGroups.operationsAdmins, authGroups.businessAdmins]
    },
    cacheNotFound: {
        path: `/cache/*`,
        component: `FileNotFound`,
    },
    notFound: {
        path: `*`,
        component: `NotFound`
    },
};

export const routeWithParams = (path,
                                params) => {
    const filledPath = Object.keys(params).reduce((acc,
                                                   key) => {
            return acc.replace(`:${key}`,
                params[key]);
        },
        path);
    return filledPath.replace(/\/?:\w+/g,
        ``);
};

export const getRouteNameByPath = (pathname) => {
    for (const route of Object.values(routes)) {
        if (matchPath(route.path, pathname)) {
            return route.name;
        }
    }
    return null;
};