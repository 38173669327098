import { getCurrentOrigin, getCurrentUri } from "helpers/url";
import { routes } from "helpers/routes";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

export const authGroups = {
    users: process.env.REACT_APP_AUTH_USERS_GROUP,
    fleetAdmins: process.env.REACT_APP_AUTH_FLEET_ADMINS_GROUP,
    operationsAdmins: process.env.REACT_APP_AUTH_OPERATIONS_ADMINS_GROUP,
    businessAdmins: process.env.REACT_APP_AUTH_BUSINESS_ADMINS_GROUP,
}

const authenticate = async (groups = []) => {
    try {
        let token = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY);
        if (!token) return [false, null, null, false];
        let decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const timeLeft = decodedToken.exp - currentTime;
        if (timeLeft <= 0) {
            return [false, null, null, false];
        }
        if (timeLeft < 30 * 60) {
            try {
                const response = await axios.post(process.env.REACT_APP_SSO_REFRESH_URL, {}, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    withCredentials: true
                });
                token = response.data.data.token;
                localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY, token);
                decodedToken = jwtDecode(token);
            } catch (refreshError) {
                console.error(`Error refreshing token: ${refreshError}`);
                return [false, null, null, false];
            }
        }
        const name = decodedToken.name || null;
        const email = decodedToken.email || null;
        const userGroups = decodedToken.groups || [];
        const isAuthorized = groups.length === 0 || groups.some(group => userGroups.includes(group));
        return [true, name, email, isAuthorized, userGroups];
    } catch (error) {
        console.error(`Error performing authentication: ${error}`);
        return [false, null, null, false];
    }
};

const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY);
}

const redirectToLogin = (targetHome = false) => {
    const loginUrl = process.env.REACT_APP_SSO_LOGIN_URL;
    const target = `${getCurrentOrigin()}${routes.loginCallback.path}`;
    const to = targetHome ? routes.home.path : getCurrentUri();
    window.location.href = `${loginUrl}?target=${encodeURIComponent(target)}&to=${encodeURIComponent(to)}`;
};

const logout = async (navigate) => {
    try {
        const token = getToken();
        if (!token) {
            navigate(routes.logoutSuccess.path);
            return;
        }

        await axios.post(process.env.REACT_APP_SSO_LOGOUT_URL, {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            withCredentials: true
        });

        localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY);
    } catch (error) {
        console.error(`Error during logout: ${error}`);
    }
    navigate(routes.logoutSuccess.path);
};

export { authenticate, getToken, redirectToLogin, logout };
