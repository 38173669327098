import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "helpers/routes";
import { FullPageLoading } from "components/utils/ui";

function LoginCallback() {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const to = urlParams.get("to");

    useEffect(() => {
        if (token) {
            localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY, token);
            setTimeout(() => {
                const destination = to && to.startsWith("/") ? to : routes.home.path;
                navigate(destination);
            }, 0);
        }
    }, [navigate, token, to]);

    return <FullPageLoading/>;
}

export { LoginCallback };
