import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, LoadingSpinner, PageTitle, SearchableSelect } from "components/utils/ui";
import { languageOptions } from "helpers/translations";
import i18n from "i18next";

const Account = ({authInfo}) => {
    const {t} = useTranslation();

    const [language, setLanguage] = useState(i18n.language.split("-")[0]);
    const [changingLanguage, setChangingLanguage] = useState(false);

    const handleLanguageChange = (selectedOption) => {
        setChangingLanguage(true)
        setLanguage(selectedOption);
        i18n.changeLanguage(selectedOption).then(r => setChangingLanguage(false));
    };

    return (
        <>
            <PageTitle>
                <div className="text-size-lg">{t("helloComma")}</div>
                <div className="h1 fw-bold mb-0">{authInfo.user}</div>
            </PageTitle>
            <Card>
                <div className="fw-bold text-size-lg">{t("settings")}</div>
                <div className="d-flex mt-2">
                    <div className="fw-bold me-1">{`${t("language")}:`}</div>
                    <div className="text-color-med">
                        {changingLanguage ? (
                            <LoadingSpinner/>
                        ) : (
                            <SearchableSelect
                                loaded={true}
                                options={languageOptions}
                                setSelectedOption={handleLanguageChange}
                                selectedOption={language}
                            />
                        )}
                    </div>
                </div>
            </Card>
        </>
    )
}

export { Account };