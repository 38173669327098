const downloadFile = (text, name = "file") => {
    try {
        const element = document.createElement("a");
        const file = new Blob([text], {type: "text/plain"});
        element.href = URL.createObjectURL(file);
        element.download = `${name}.txt`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        return true;
    } catch (error) {
        console.error(`Error downloading file: ${error.message}`);
        return false;
    }
};

export { downloadFile };
