import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    LoadingSpinner,
    PageTitle,
    RobotMaintenanceStatusSelector,
    TitleValueInput,
    tooltipStyles
} from "components/utils/ui";
import { apiEndpoints, apiRequest, requestTypes } from "services/api";
import { routes, routeWithParams } from "helpers/routes";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { useHotkeys } from "react-hotkeys-hook";
import { HistoryBackButton } from "components/utils/navigation";

const UpdateRobotMaintenanceStatus = ({authInfo}) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    const [maintenanceStatus, setMaintenanceStatus] = useState(null);
    const [notes, setNotes] = useState(null);

    const [isUpdating, setIsUpdating] = useState(false);
    const [data, setData] = useState(undefined);
    const [error, setError] = useState(undefined);

    useHotkeys("esc", () => navigate(routeWithParams(routes.robotMaintenanceHistory.path, {id: id})));

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const update = () => {
        if (!maintenanceStatus) {
            window.alert(t("maintenanceStatusRequired"));
            return;
        }
        if (!id || !authInfo || !authInfo.email || !maintenanceStatus) {
            window.alert(t("errorUpdatingStatus"));
            return;
        }
        setIsUpdating(true);
        apiRequest(
            setData,
            setError,
            requestTypes.POST,
            apiEndpoints.updateRobotMaintenanceStatus.constructUrl(),
            {
                robotId: id,
                userId: authInfo.email,
                notes: notes,
                maintenanceStatus: maintenanceStatus,
            }
        ).then(apiRequestSuccess => {
            if (!apiRequestSuccess) alert(t("errorUpdatingStatus"));
            setIsUpdating(false);
        });
    }

    useEffect(() => {
        if (data === undefined || !data.robotId) return;
        navigate(routeWithParams(routes.robotMaintenanceHistory.path, {id: data.robotId}));
    }, [data]);

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <HistoryBackButton className="mb-1"/>
                    {t("updateRobotMaintenanceStatus", {robot: id})}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <Card>
                        <div className="fw-bold text-size-lg">{t("info")}</div>
                        <div className="d-flex mt-2">
                            <div className="fw-bold me-1">{`${t("maintenanceStatus")}:`}</div>
                            <div className="text-color-med flex-grow-1">
                                <RobotMaintenanceStatusSelector loaded={true} robotMaintenanceStatus={maintenanceStatus}
                                                                setRobotMaintenanceStatus={setMaintenanceStatus}/>
                            </div>
                            {!maintenanceStatus && (
                                <>
                                    <FontAwesomeIcon icon={faExclamationCircle} className="text-color-accent"
                                                     data-tooltip-id="maintenance-status-required-tooltip"/>
                                    <Tooltip
                                        id={`maintenance-status-required-tooltip`}
                                        place="left"
                                        content={t("maintenanceStatusRequired")}
                                        style={tooltipStyles}
                                    />
                                </>
                            )}
                        </div>
                        <TitleValueInput
                            title={`${t("notes")}:`}
                            value={notes}
                            setValue={setNotes}
                        />
                        <div className="align-items-center d-flex fst-italic text-color-med mt-2">
                            <FontAwesomeIcon icon={faCircleInfo} className="pe-1 text-size-xs"/>
                            {t("pleaseDoubleCheckAllInfo")}
                        </div>
                    </Card>
                </div>
                <div className="col-12 my-2 my-lg-3 d-flex justify-content-center">
                    {isUpdating ? (
                        <LoadingSpinner size={38}/>
                    ) : (
                        <>
                            <div className="col-6 text-end">
                                <button type="button" className="btn bg-accent me-3 text-white"
                                        onClick={() => update()} disabled={!maintenanceStatus}>
                                    {t("save")}
                                </button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn bg-med ms-3 text-white" onClick={() => {
                                    navigate(routeWithParams(routes.robot.path, {id: id}));
                                }}>
                                    {t("cancel")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export { UpdateRobotMaintenanceStatus };
