import React from "react";
import PropTypes from "prop-types";
import { MainNavbar } from "components/utils/navigation";
import "./index.css"
import Div100vh from "react-div-100vh";

const DefaultPortalTemplate = ({authInfo, children}) => {
    return (
        <Div100vh>
            <div className="d-flex h-100">
                <MainNavbar authInfo={authInfo}/>
                <div className="content flex-grow-1 overflow-y-scroll overflow-x-hidden">
                    <div className="d-flex flex-column p-2 p-lg-5 min-h-100">
                        {!!authInfo.authenticated && (<div className="mobile-navbar-top-bar-spacer"/>)}
                        {children}
                    </div>
                </div>
            </div>
        </Div100vh>
    );
};

DefaultPortalTemplate.propTypes = {
    children: PropTypes.node.isRequired,
};

export { DefaultPortalTemplate };
