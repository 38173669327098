import React, { useEffect, useState } from "react";

const ModalDialog = ({ show, clickOutsideToClose = true, onClose, onConfirm, yesText, noText, title, children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [animationStyle, setAnimationStyle] = useState({
        opacity: 0,
        visibility: "hidden",
    });

    useEffect(() => {
        if (show) {
            setIsVisible(true);
            setTimeout(() => {
                setAnimationStyle({
                    opacity: 1,
                    visibility: "visible",
                    transition: "opacity 0.25s ease",
                });
            }, 0);
        } else {
            setAnimationStyle({
                opacity: 0,
                visibility: "visible",
                transition: "opacity 0.25s ease",
            });
            const timer = setTimeout(() => {
                setAnimationStyle({
                    opacity: 0,
                    visibility: "hidden",
                    transition: "opacity 0.25s ease",
                });
                setIsVisible(false);
            }, 250);
            return () => clearTimeout(timer);
        }
    }, [show]);

    if (!isVisible && !show) return null;

    return (
        <div
            className="modal-overlay"
            tabIndex="-1"
            role="dialog"
            style={{
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1050,
                backdropFilter: "blur(4px)",
                WebkitBackdropFilter: "blur(4px)",
                ...animationStyle,
            }}
            onClick={() => {if (clickOutsideToClose) onClose()}}
        >
            <div
                style={{
                    backgroundColor: `var(--white)`,
                    borderRadius: `32px`,
                    marginLeft: `auto`,
                    marginRight: `auto`,
                    maxWidth: `var(--bs-modal-width)`,
                }}
                role="document"
            >
                <div className="d-flex flex-column">
                    <div className="p-4">
                        <div className="align-items-center d-flex">
                            <div className="flex-grow-1 h5 mb-0">{title}</div>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="mt-3">{children}</div>
                        <div className="d-flex mt-4">
                            <div className="flex-grow-1"></div>
                            <button
                                type="button"
                                className="btn bg-accent text-color-white me-3"
                                onClick={onConfirm}
                            >
                                {yesText}
                            </button>
                            <button
                                type="button"
                                className="btn bg-med text-color-white"
                                onClick={onClose}
                            >
                                {noText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ModalDialog };
