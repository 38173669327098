import React from "react";
import PropTypes from "prop-types";

const Card = ({color: background = `var(--white)`, shadow = true, className = "", children}) => {
    return (
        <div className={`card m-2 m-lg-3 ${className}`} style={{
            background: background,
            border: `1px solid var(--light-xtrans)`,
            borderRadius: `16px`,
            boxShadow: shadow ? `var(--light-xtrans) 0px 0px 2px 0px, var(--light-trans) 0px 12px 24px -4px` : `none`,
        }}>
            <div className="card-body">
                {children}
            </div>
        </div>
    );
};

Card.propTypes = {
    color: PropTypes.string,
    shadow: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export { Card };
