import { useEffect, useState } from "react";
import { apiEndpoints, apiRequest, requestTypes } from "services/api";
import { LoadingSpinner, SearchableSelect } from "components/utils/ui";

const MobileProviderSelector = ({loaded, mobileProvider, setMobileProvider}) => {
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const getDataApiEndpoint = apiEndpoints.mobileProviderList;
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl());
    }, []);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    const processOptions = (data) => {
        return data.reduce((obj, value) => {
            obj[value] = value;
            return obj;
        }, {});
    }

    if (data === undefined || !loaded) {
        return <LoadingSpinner/>;
    }

    return (
        <SearchableSelect loaded={true} options={processOptions(data)} setSelectedOption={setMobileProvider}
                          selectedOption={mobileProvider}/>
    )

}

export { MobileProviderSelector };