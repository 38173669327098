import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiEndpoints, apiRequest, createEmptyApiResponse, requestTypes } from "services/api";
import { Card, FullPageLoading, LinkWithParams, LoadingSpinner, PageTitle, SearchableList } from "components/utils/ui";
import { useNavigate, useParams } from "react-router-dom";
import { routes, routeWithParams } from "helpers/routes";
import { dateObjectToPrettyDateWithYear, dateObjectToPrettyDateWithYearAndTime } from "helpers/date";
import { useHotkeys } from "react-hotkeys-hook";
import { HistoryBackButton, robotNavigationMap } from "components/utils/navigation";
import { useHistoryContext } from "contexts/HistoryProvider";

const RobotActivity = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const {updateCurrentRouteName} = useHistoryContext();

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [data, setData] = useState(undefined);

    useHotkeys("esc", () => navigate(routeWithParams(routes.robot.path, {id: id})));
    useHotkeys("shift+right", () => navigate(routeWithParams(routes.robotMaintenanceHistory.path, {id: robotNavigationMap[id].next})));
    useHotkeys("shift+left", () => navigate(routeWithParams(routes.robotMaintenanceHistory.path, {id: robotNavigationMap[id].prev})));

    useEffect(() => {
        setLoaded(false);
        const getDataApiEndpoint = apiEndpoints.robotActivity;
        createEmptyApiResponse(requestTypes.GET, getDataApiEndpoint.schemaPath).then(emptyApiResponse => {
            if (!data && emptyApiResponse) setData(emptyApiResponse);
        });
        apiRequest(setData, setError, requestTypes.GET, getDataApiEndpoint.constructUrl(id)).then(apiRequestSuccess => {
            if (apiRequestSuccess) setLoaded(true);
        })
    }, [id]);

    useEffect(() => {
        if (!!error) {
            alert(error);
            setError(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (!!data) updateCurrentRouteName(t("robotActivityFeed", {robot: id}));
    }, [data]);

    const activityFeedTableHeaders = {
        event: t("event"),
        type: t("type"),
        dates: t("dates")
    };

    const processActivityFeed = (activities) => {
        return activities.map(activity => {
            let dates;
            const date1 = new Date(activity.date1);
            if (!!activity.date2) {
                const date2 = new Date(activity.date2);
                dates = `${dateObjectToPrettyDateWithYear(date1)}-${dateObjectToPrettyDateWithYear(date2)}`;
            } else dates = dateObjectToPrettyDateWithYearAndTime(date1)

            let event;
            let type;
            if (activity.type === "deployment") {
                event = (
                    <LinkWithParams baseUrl={routes.deployment.path} params={{id: activity.id}} text={activity.name}/>);
                type = t("deployment");
            }
            if (activity.type === "robotReservation") {
                event = (<LinkWithParams baseUrl={routes.robotReservation.path} params={{reservationId: activity.id}}
                                         text={activity.name}/>);
                type = t("robotReservation");
            }
            if (activity.type === "robotMaintenanceEvent") {
                event = (<LinkWithParams baseUrl={routes.robotMaintenanceHistory.path} params={{id: activity.robotId}}
                                         text={t(`api:robot_maintenance_status_list:${activity.name}`)}/>);
                type = t("maintenance");
            }
            if (activity.type === "robotCalibrationEvent") {
                event = (<LinkWithParams baseUrl={routes.robotCalibrationHistory.path} params={{id: activity.robotId}}
                                         text={t(`api:robot_calibration_status_list:${activity.name}`)}/>);
                type = t("calibration");
            }

            return {
                event: event,
                type: type,
                dates: dates
            };
        })
    }

    if (data === undefined) {
        return <FullPageLoading/>;
    }

    return (
        <>
            <PageTitle className="align-items-center d-flex">
                <div>
                    <HistoryBackButton className="mb-1"/>
                    {loaded ? (
                        t("robotActivityFeed", {robot: id})
                    ) : (
                        <LoadingSpinner size={46}/>
                    )}
                </div>
                <div className="flex-grow-1"></div>
            </PageTitle>
            <Card>
                <SearchableList
                    loaded={loaded}
                    title={t("activityFeed")}
                    columnNames={activityFeedTableHeaders}
                    data={processActivityFeed(data.activity)}
                    sortable={false}
                />
            </Card>
        </>
    )
}

export { RobotActivity };