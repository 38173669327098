import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import { invisibleInputStyles, LoadingSpinner } from "components/utils/ui";

const styles = {
    dropdownList: {
        background: "var(--white)",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        listStyle: "none",
        left: 0,
        margin: 0,
        maxHeight: "192px",
        overflowY: "auto",
        padding: 0,
        position: "absolute",
        zIndex: 9999,
    },
    dropdownListOpen: {
        border: `1px solid var(--light-xtrans)`,
        borderTop: `1px solid var(--accent)`,
        boxShadow: `var(--light-xtrans) 0px 0px 2px 0px, var(--light-trans) 0px 12px 24px -4px`,
    },
    inputContainer: {
        borderBottom: "1px solid var(--med)"
    }
};

const SearchableSelect = ({
                              loaded,
                              options,
                              placeholder,
                              selectedOption,
                              setSelectedOption,
                              shiftRight = 0,
                              expandWidth = 0,
                              className = ""
                          }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(Object.entries(options));
    const [showDropdown, setShowDropdown] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [previousSelectedText, setPreviousSelectedText] = useState("");
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const listItemsRef = useRef([]);

    useEffect(() => {
        if (selectedOption && !options[selectedOption]) {
            setSelectedOption(null);
            setSearchTerm("");
        } else if (selectedOption) {
            setSearchTerm(options[selectedOption]);
            setPreviousSelectedText(options[selectedOption]);
        }
        setFilteredOptions(Object.entries(options));
    }, [options, selectedOption]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setFilteredOptions(
            Object.entries(options).filter(([key, text]) =>
                text.toLowerCase().includes(value.toLowerCase())
            )
        );
        setHighlightedIndex(-1);
    };

    const handleOptionSelect = (key, text) => {
        setSelectedOption(key);
        setSearchTerm(text);
        setPreviousSelectedText(text);
        setShowDropdown(false);
        inputRef.current.blur(); // Blur the input to remove focus
    };

    const handleInputClick = () => {
        setPreviousSelectedText(searchTerm);
        setSearchTerm("");
        setShowDropdown(true);
    };

    const handleIconClick = () => {
        setShowDropdown((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (
            inputRef.current &&
            !inputRef.current.contains(event.target) &&
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            restorePreviousSelection();
        }
    };

    const handleKeyDown = (e) => {
        if (showDropdown) {
            if (e.key === "ArrowDown") {
                e.preventDefault();
                setHighlightedIndex((prevIndex) => {
                    const nextIndex = prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex;
                    scrollToItem(nextIndex);
                    return nextIndex;
                });
            } else if (e.key === "ArrowUp") {
                e.preventDefault();
                setHighlightedIndex((prevIndex) => {
                    const nextIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
                    scrollToItem(nextIndex);
                    return nextIndex;
                });
            } else if (e.key === "Enter" && highlightedIndex >= 0) {
                e.preventDefault();
                const [key, text] = filteredOptions[highlightedIndex];
                handleOptionSelect(key, text);
            } else if (e.key === "Escape") {
                restorePreviousSelection();
            }
        }
    };

    const scrollToItem = (index) => {
        if (dropdownRef.current && listItemsRef.current[index]) {
            const dropdown = dropdownRef.current;
            const item = listItemsRef.current[index];
            const dropdownTop = dropdown.scrollTop;
            const dropdownBottom = dropdownTop + dropdown.clientHeight;
            const itemTop = item.offsetTop;
            const itemBottom = itemTop + item.clientHeight;

            if (itemBottom > dropdownBottom) {
                dropdown.scrollTop = itemBottom - dropdown.clientHeight;
            } else if (itemTop < dropdownTop) {
                dropdown.scrollTop = itemTop;
            }
        }
    };

    const restorePreviousSelection = () => {
        setSearchTerm(previousSelectedText);
        setShowDropdown(false);
        setTimeout(() => inputRef.current.blur(), 0);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showDropdown && dropdownRef.current && inputRef.current) {
            const inputRect = inputRef.current.getBoundingClientRect();
            dropdownRef.current.style.top = `${inputRect.bottom + window.scrollY}px`;
            dropdownRef.current.style.left = `${inputRect.left + window.scrollX + shiftRight}px`;
            dropdownRef.current.style.width = `${inputRect.width + expandWidth}px`;
        }
    }, [showDropdown]);

    const dropdown = (
        <ul
            ref={dropdownRef}
            style={{
                ...styles.dropdownList,
                ...(showDropdown ? styles.dropdownListOpen : {}),
            }}
        >
            {loaded ? (
                filteredOptions.map(([key, text], index) => (
                    <li
                        key={key}
                        onClick={() => handleOptionSelect(key, text)}
                        className={`clickable searchable-select-dropdown-list-item p-1 ${highlightedIndex === index ? "highlighted" : ""}`}
                        ref={(el) => (listItemsRef.current[index] = el)}
                    >
                        {text}
                    </li>
                ))
            ) : (
                <li className="d-flex justify-content-center p-1"><LoadingSpinner/></li>
            )}
        </ul>
    );

    return (
        <div className={`position-relative ${className}`} style={styles.inputContainer}>
            <div className="align-items-center d-flex justify-content-between">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onClick={handleInputClick}
                    style={invisibleInputStyles}
                    placeholder={placeholder}
                    className="searchable-select-input text-color-dark"
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                />
                <div className="clickable px-2" onClick={handleIconClick}>
                    <FontAwesomeIcon icon={showDropdown ? faChevronUp : faChevronDown} className="text-color-accent"/>
                </div>
            </div>
            {showDropdown && createPortal(dropdown, document.body)}
        </div>
    );
};

export { SearchableSelect };
