import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const ComingSoon = () => {
    const {t} = useTranslation();

    return (
        <div className="d-flex justify-content-center align-items-center flex-grow-1">
            <div className="text-center">
                <FontAwesomeIcon icon={faHourglassHalf} size="3x" style={{marginBottom: "8px"}}/>
                <div className="fw-bold text-size-xl">Almost there!</div>
                <div className="text-color-med">This page is coming soon.</div>
            </div>
        </div>
    )
}

export { ComingSoon };