import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const ExternalLink = ({href = "", text = "", className = ""}) => {
    return (
        <div className={className}>
            <a
                className="clickable text-color-accent"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
            >
                {text}&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/>
            </a>
        </div>
    );
};

ExternalLink.propTypes = {
    href: PropTypes.string.isRequired,
    text:
    PropTypes.string.isRequired,
    className:
    PropTypes.string,
};

export {
    ExternalLink
};
