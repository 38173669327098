import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const ProgressBar = ({loaded, value, color = `var(--accent)`, height = 8, showText = false}) => {
    const containerStyle = {
        height: `${height}px`,
        width: `100%`,
        backgroundColor: `var(--light-trans)`,
        borderRadius: `${height / 2}px`,
        overflow: `hidden`,
        position: `relative`,
    };

    const fillerStyle = {
        height: `100%`,
        width: `${value * 100}%`,
        backgroundColor: color,
        textAlign: `right`,
        borderRadius: `inherit`,
        transition: `width 1s ease`,
        position: `relative`,
    };

    const shimmerStyle = {
        display: `block`,
        height: `100%`,
        width: `100%`,
        position: `absolute`,
        top: `0`,
        left: `0`,
        background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%)`,
        animation: `shimmer 1.0s infinite`,
    };

    const textStyle = {
        color: `var(--white)`,
        fontSize: `${height / 2}px`,
        lineHeight: `${height / 2}px`,
        padding: `${height / 4}px ${height / 2}px ${height / 4}px`,
    };

    return (
        <div style={containerStyle}>
            {
                loaded ? (
                    <div style={fillerStyle}>
                        {showText && <div style={textStyle}>
                            {`${Math.round(value * 100)}%`}
                        </div>}
                    </div>
                ) : (<div style={shimmerStyle}></div>
                )
            }
        </div>
    );
};

ProgressBar.propTypes = {
    loaded: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string,
    height: PropTypes.number,
    showText: PropTypes.bool,
};

export { ProgressBar };
