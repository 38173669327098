import React from "react";
import PropTypes from "prop-types";
import { LoadingSpinner } from "components/utils/ui";

const TitleValueLine = ({loaded, title, value, className = ``}) => {
    return (
        <div className={`d-flex ${className}`}>
            <div className={`fw-bold me-1 text-nowrap`}>{title}</div>
            <div className="text-color-med">
                {loaded ? value : <LoadingSpinner/>}
            </div>
        </div>
    );
};

TitleValueLine.propTypes = {
    loaded: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
    ]),
    className: PropTypes.string,
};

export { TitleValueLine };
